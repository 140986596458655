import React, { Component } from 'react';
import * as Shared from '../../../../shared/sharedTypes'
import { Document } from '../../../../shared/documentUpload/documentComponent';
import { GlobalService } from '../../../../services/globalService';
import { ControllingPersonService } from '../../../../services/controllingPersonService';
import { ControllingPersonDocumentService } from '../../../../services/controllingPersonDocumentService';
import { ValidationService } from '../../../../services/validationService';
import styles from "../../../../../assets/styles/notifications.module.scss";

class State {
	requiredDocuments: Array<Shared.IDocument> = []
}

export class NonRegulatedControllingPersondDocuments extends Component<Shared.IEntityProps> {
	state = new State();

	constructor(props: Shared.IEntityProps) {
		super(props)
		this.loadDocuments = this.loadDocuments.bind(this);
		this.setNextActiveStep = this.setNextActiveStep.bind(this);
		this.uploadDocument = this.uploadDocument.bind(this);
		this.saveDocument = this.saveDocument.bind(this);
		this.clearDocument = this.clearDocument.bind(this);
		this.validateControllingPersonDocuments = this.validateControllingPersonDocuments.bind(this);
	}

	async componentDidMount() {
		await this.loadDocuments();
		const scrollDoc = this.state.requiredDocuments.find(x => x.type === this.props.activeNotification?.notificationKey);
		scrollDoc?.ref.current.scrollIntoView();
	}

	private async loadDocuments() {
		let docs: Array<Shared.IDocument> = [];
		let doc: Shared.IDocument = Shared.EmptyDocument;
		let documents: Array<Shared.IDocument> = this.props.controllingPerson!.documents;
		let highRiskInvestor: boolean = this.props.entityInvestor?.highRiskInvestor === true;
		let controllingPersonId: string = this.props.controllingPerson!.id;
		if (this.props.controllingPerson?.requiredDocuments !== undefined) {
			this.props.controllingPerson?.requiredDocuments.forEach(function (item) {
				doc = {} as Shared.IDocument;
				let document: Shared.IDocument | undefined = documents.find(x => x.type === item.type);
				doc.title = highRiskInvestor ? item.highRiskTitle : item.lowRiskTitle;
				doc.controllingPersonId = controllingPersonId;
				if (document !== undefined) {
					doc.content = document.content;
					doc.id = document.id;
					doc.name = document.name;
					doc.notes = document.notes;
				} else {
					doc.id = 0;
					doc.name = '';
					doc.notes = '';
					doc.content = null;
					doc.type = item.type;
				}
				docs.push({ ...doc, ref:React.createRef() });
			})
		};

		this.setState({
			requiredDocuments: docs
		});
	}

	async saveDocument(values: Shared.IDocument) {
		await ControllingPersonDocumentService.saveDocument({...values, ref:''})
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.populateControllingPersonData(values.controllingPersonId);
					await this.validateControllingPersonDocuments();
				}
			});
	}

	async uploadDocument(values: Shared.IDocument) {
		if (values.content.files[0] === undefined) {
			return
		}
		const formData = new FormData();
		formData.append('name', values.content.files[0].name);
		formData.append('type', values.type);
		formData.append('content', values.content.files[0]);
		formData.append('ControllingPersonId', values.controllingPersonId);
		await ControllingPersonDocumentService.uploadDocument(formData)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.populateControllingPersonData(values.controllingPersonId);
					await this.validateControllingPersonDocuments();
				}
			});
	}

	async clearDocument(values: Shared.IDocument) {
		await ControllingPersonDocumentService.clearDocument(values.id, values.controllingPersonId)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.populateControllingPersonData(values.controllingPersonId);
					await this.validateControllingPersonDocuments();
				}
			});
	}

	async populateControllingPersonData(id: string) {
		await ControllingPersonService.getControllingPerson(id)
			.then(async result => {
				await this.props.updateControllingPerson!(result);
				await this.loadDocuments();
			})
	};

	async setNextActiveStep() {
		await this.validateControllingPersonDocuments();
		this.props.handleClose!();
	}

	async validateControllingPersonDocuments() {
		await ValidationService.validateControllingPerson(this.props.controllingPerson!.id, "Documents")
			.then(result => {
				GlobalService.sendNotification(result)
			});
	}

	render() {
		return (
			<>
				{this.props.bindGetNextStepHandler!(this.setNextActiveStep)}
				<div className="stepContent">
					<h1>{this.props.controllingPerson!.type} Documents</h1>
					<div className={`stepLeft`}>
						<div className={`overflowText`}>
							<h2>Upload Documents</h2>
							<article>
								<p>Maples Compliance Services (Cayman) Limited have been engaged to identify investors in line with the Cayman Islands Know Your Customer (“KYC”) standards.<br /><br /> Please provide the following documents.:</p>
							</article>
						</div>
					</div>
					<div className={`stepRight stepDetails`}>

						<div className="rowWrap">
							{this.state.requiredDocuments.map((requiredDocument, index) => (
								<Document
									key={requiredDocument.title}
									id={"docUpload" + index}
									document={requiredDocument}
									uploadDocument={this.uploadDocument}
									saveDocument={this.saveDocument}
									clearDocument={this.clearDocument}
									refs={requiredDocument.ref}
									classes={`${(this.props.activeNotification?.notificationKey === requiredDocument.type && requiredDocument.name === "") ? styles.hasNotification : null}`}
								/>
							))}
						</div>
					</div>
				</div>
			</>
		);
	}
}