import React, { Component } from 'react';
import styles from './celebration.module.scss';
import animation from '../../../assets/celebration.json';
import Lottie from 'lottie-react';
import ConfettiGenerator from "confetti-js";


export class Celebration extends Component {

	componentDidMount() {
		const confettiSettings = {
			target: 'my-canvas',
			max: 50,
			size: 2,
			props: ['square', 'line'],
			colors: [
				[195, 32, 50],
				[111, 79, 127]
			],
			rotate: true,
			start_from_edge: true
		};
		const confetti = new ConfettiGenerator(confettiSettings);
		confetti.render();

		return () => confetti.clear();
	}

	lottieOptions = {
		loop: false,
		autoplay: true,
		prerender: true,
		animationData: animation
	}

	render() {
		return (
			<div className={styles.celebrationLayout}>
				<div className={styles.intro}>
					<h1>You're <span>Done!</span></h1>
					<div className={styles.content}>
						<p>Thanks for submitting your documents. We’ll review them and be in touch as soon as possible.</p>
					</div>

				</div>
				<div>
					<Lottie {...this.lottieOptions} />
					<canvas className={styles.confetti} id="my-canvas"></canvas>
				</div>
			</div>
		);
	}

}