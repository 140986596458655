export var maplesprimary = {
  50: '#e4f3fa',
  100: '#bbe0f3',
  200: '#8dcceb',
  300: '#5fb8e2',
  400: '#3da8dc',
  500: '#1b99d6',
  600: '#1891d1',
  700: '#1486cc',
  800: '#107cc6',
  900: '#086bbc',
  A100: '#e6f3ff',
  A200: '#b3d9ff',
  A400: '#80c0ff',
  A700: '#67b3ff'
};

export var maplesaccent = {
  50: '#fef0e5',
  100: '#fcdabd',
  200: '#fbc292',
  300: '#f9a966',
  400: '#f79645',
  500: '#f68424',
  600: '#f57c20',
  700: '#f3711b',
  800: '#f26716',
  900: '#ef540d',
  A100: '#ffffff',
  A200: '#ffeee7',
  A400: '#ffc8b4',
  A700: '#ffb69b'
}

export var mapleswarn = {
  50: '#f8e4e6',
  100: '#eebcc1',
  200: '#e29098',
  300: '#d6636f',
  400: '#ce4150',
  500: '#c52031',
  600: '#bf1c2c',
  700: '#b81825',
  800: '#b0131f',
  900: '#a30b13',
  A100: '#ffd1d3',
  A200: '#ff9ea1',
  A400: '#ff6b70',
  A700: '#ff5157',
}