import React, { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import { TextFieldMaples } from 'msg-react-lib';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DocumentInfo(props) {
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('xs');

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Document Notes</DialogTitle>
      <Formik enableReinitialize initialValues={props.document} onSubmit={props.handleSubmit}>
        {
          ({ submitForm }) => (
            <Fragment>
              <DialogContent>
                <Form>
                  <div className="">
                    <div className="">
                      <Field
                        style={{ 'width': 400, 'marginBottom':0 }}
                        name="notes"
                        component={TextFieldMaples}
									  label="Notes"
									  autoFocus
                      />
                    </div>
                  </div>
                </Form>
              </DialogContent>
              <DialogActions>
					  <Button variant="contained" color="primary" type="submit" onClick={submitForm} disableElevation>Save</Button>
                <Button onClick={props.handleClose} color="primary">Cancel</Button>
              </DialogActions>
            </Fragment>
          )
        }
      </Formik>
    </Dialog>
  );
}