import React, { Component, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import styles from './dueDiligenceConsent.module.scss';
import * as Shared from '../shared/sharedTypes'
import { InvestorService } from '../services/investorService'
import MessageDialog from '../shared/dialogs/dialogMessage';
import { ValidationService } from '../services/validationService';
import { GlobalService } from '../services/globalService';

type MyState = {
	investor: Shared.IInvestor;
	dialogOpen: boolean,
	messageTitle: string,
	messageText: string,
	radioActive: string,
	hasNotification:boolean,
}

export class DueDiligenceConsent extends Component<Shared.IProps, MyState>{
	notificationField: string;

	constructor(props: Shared.IProps) {
		super(props);
		const notificationKey = this.props.activeNotification?.length > 0 ? this.props.activeNotification[0].notificationKey : this.props.activeNotification?.notificationKey;
		this.notificationField = notificationKey ? notificationKey.charAt(0).toLowerCase() + notificationKey.slice(1) : null;
		this.state = {
			investor: (this.props.investor !== undefined) ? this.props.investor : Shared.EmptyInvestor,
			dialogOpen: false,
			messageTitle: "",
			messageText: "",
			radioActive: (this.props.investor !== undefined) ? this.props.investor.dueDiligenceConsent : "",
			hasNotification: this.notificationField === "dueDiligenceConsent",
		}
		this.handleDialogClose = this.handleDialogClose.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async handleSubmit(values: Shared.IInvestor) {
		values.mainWorkflowCompleted = true;
		await InvestorService.update(values)
		if (this.props.investor!.type === 'Individual') {
			await InvestorService.updateMainWorkflowStatus(true);
		}
		await ValidationService.validateInvestor('DueDiligenceConsent')
			.then(result => {
				this.props.setCaseIsValid(result.totalIssues === 0)
				GlobalService.sendNotification(result)
			})
		await this.props.updateInvestor(values);
	}

	handleDialogOpen(title, message) {
		this.setState({
			messageTitle: title,
			messageText: message,
			dialogOpen: true
		})
	}

	handleDialogClose() {
		this.setState({
			dialogOpen: false
		})
	}

	handleChange(e) {
		this.setState({
			radioActive: e.target.value
		})
	}

	public render() {
		return (
			<div className="stepContent">

				<Formik enableReinitialize initialValues={this.state.investor} onSubmit={this.handleSubmit} >
					{
						(formikProps) => {
							this.props.bindSubmitFormHandler!(formikProps.submitForm);
							this.props.setFormikProps!(formikProps);
							return (
								<>
									<h1>Due Diligence Consent</h1>
									<div className={`stepLeft`}>
										<div className={`overflowText`}>
											<div>
												<h3 className="stepCount">{`Step ${this.props.currentStep}`}</h3>
												<h2>Leverage your KYC across other Funds and Services</h2>
											</div>

											<article className={`smallText`}>
												<p>Maples Compliance Services Cayman Limited (“MCSCL”) performs investor due diligence on behalf of many different investment funds. Depending on the Fund, the investor due diligence performed by MCSCL may include either Investor KYC or FATCA/CRS checks, or both).</p>
												<ul>
													<li><strong>Investor KYC </strong>is mandated by laws that tackle money laundering, terrorist financing and other forms of financial crime. This typically involves checking the identity of the investor, any legal restriction which applies to the investor, the investor’s source of funds, and so on.</li>
													<li><strong>FACTA/CRS </strong>check is mandated by laws that tackle tax evasion.  This typically involves checking the official identifiers of investors, tax residency/status of the investor, details of accounts opened by the investor, and so on.</li>
												</ul>
												<p>It is not uncommon for the same investor to be subscribed to multiple funds, none of which are in any way related to each other, but all of which receive investor due diligence services from MCSCL. Each of such funds is required to perform its own investor due diligence through MCSCL, even if the investor has already completed the due diligence in relation to one fund through MCSCL.</p>
												<p>Similarly, it is not uncommon for an investor who has subscribed to funds that receive services from MCSCL to also be subscribed to other funds that receive other services from affiliates of MCSCL within the Maples Group (e.g. funds that receive anti-money laundering compliance service or entity management service from affiliates of MCSCL). Such funds may also be required to perform their own investor due diligence through the relevant affiliate of MCSCL, even if the investor has already completed the due diligence in relation to funds that receive services from MCSCL.</p>
												<p>If you wish to avoid or minimise receiving identical or similar due diligence requests in relation to your subscription to different funds which are all serviced by MCSCL, or other funds which are serviced by affiliates of MCSCL, you can opt into either or both of the following options to leverage the information and documentation you provide to MCSCL.</p>

												<p><i>NB: Due diligence requirements vary depending on various factors and may differ by fund or by jurisdiction. Therefore, even if you opt into Option 1 and/or Option 2, MCSCL or its affiliates may still request additional due diligence information/documentation from you, where the response you provide to this KYC request proves to be insufficient or inadequate for the purpose of additional due diligence to be performed.</i></p>
											</article>
										</div>
									</div>
									<Form className={'stepRight stepDetails'}>
										<div className="rowWrap">
											<FormControlLabel
												style={{ marginLeft: '0' }}
												value="dueDiligenceConsent"
												label=""
												labelPlacement="start"
												control={
													<Field name="dueDiligenceConsent">
														{({
															field, onChange
														}) => (
															<RadioGroup value={field} className={`rowWrap ${this.state.hasNotification ? styles.hasNotification : null}`} {...field}>
																<FormControlLabel
																	className={`${styles.radioSection} ${this.state.radioActive === 'ShareAcrossMaplesGroup' ? styles.active : ''}`}
																	value="ShareAcrossMaplesGroup"
																	control={<Radio autoFocus={this.state.radioActive === 'ShareAcrossMaplesGroup' || this.state.radioActive === null} color="primary" />}
																	onChange={(e) => {
																		this.setState({
																			hasNotification:false
																		})
																		this.handleChange(e)
																	}}
																	label={
																		<Fragment>
																			<div>Option 1: Opt-in to leverage the same due diligence across all funds that receive services from any affiliate of MCSCL.</div>
																			<button type="button" className="iconButton smallText"
																				onClick={() => this.handleDialogOpen('Option 1', ' I/we hereby consent to MCSCL sharing the due diligence record it holds in relation to me/us (including my/our due diligence documentation previously provided, any additional documentation I/we provide, and any related information derived from them) with any Maples Group affiliate of MCSCL anywhere in the world, if and to the extent there is a need to perform due diligence on me/us in relation to any other fund to which I/we currently subscribe or may in the future subscribe, and which receive services from the relevant affiliate of MCSCL, regardless of whether or not such other fund is affiliated in any way with the particular fund in respect of which I/we received this consent request.')}>
																				GUIDE
																			</button>
																		</Fragment>
																	}
																/>
																<FormControlLabel
																	className={`${styles.radioSection}  ${this.state.radioActive === 'ShareOnlyWithinTheSameMaplesEntity' ? styles.active : ''}`}
																	value="ShareOnlyWithinTheSameMaplesEntity"
																	control={<Radio autoFocus={this.state.radioActive === 'ShareOnlyWithinTheSameMaplesEntity'} color="primary" />}
																	onChange={(e) => this.handleChange(e)}
																	label={
																		<Fragment>
																			<div>
																				Option 2: Opt-in to leverage the same due diligence across all funds but only those that receive services from MCSCL.
										</div>
																			<button type="button" className="iconButton smallText"
																				onClick={() => this.handleDialogOpen('Option 2', 'I/we hereby consent to MCSCL reusing the due diligence record it holds in relation to me/us (including my/our due diligence documentation  previously provided, any additional documentation I/we provide, and any related information derived from them) for the purpose of performing due diligence on me/us in relation to all other funds to which I/we currently subscribe or may in the future subscribe, and which receive fund administration or other related services from MCSCL, regardless of whether or not such other funds are affiliated in any way with the particular fund in respect of which I/we received this consent request.  However, I/we do not consent to my/our due diligence record to be repurposed for the benefit of affiliates of MCSCL; if and to the extent affiliates of MCSCL need to obtain information from me/us in order to perform additional due diligence on me/us, I/we expect them to make a separate request for information.')}>
																				GUIDE
										</button>
																		</Fragment>
																	}
																/>
																<FormControlLabel
																	className={`${styles.radioSection}  ${this.state.radioActive === 'No' ? styles.active : ''}`}
																	value="No"
																	onChange={(e) => this.handleChange(e)}
																	control={<Radio autoFocus={this.state.radioActive === 'No'} color="primary" />}
																	label="Option 3: I do not consent to the reuse of my Due Diligence across other funds that receive services from any affiliate of MCSCL. "
																/>
															</RadioGroup>
														)}
													</Field>
												} />
										</div>
									</Form>
								</>
							)
						}
					}
				</Formik>
				<MessageDialog
					isOpen={this.state.dialogOpen}
					handleClose={this.handleDialogClose}
					title={this.state.messageTitle}
					description={this.state.messageText}
					cancelText="Cancel"
					confirmText="Delete"
				/>
			</div>
		);
	}
}