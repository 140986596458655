import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MessageDialog(props) {
	return (
			<Dialog
				open={props.isOpen}
				onClose={props.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				{props.title ?
					<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle> : null
				}
				<DialogContent>
					{props.description}
				</DialogContent>
				<DialogActions>
				<button className="iconButton" onClick={props.handleClose} color="primary">{(props.closeText) ? props.closeText : "CLOSE"}</button>
				</DialogActions>
			</Dialog>
		);
}