import * as Shared from '../shared/sharedTypes';
import { UnauthorizedService } from './unauthorizedService';

export class ControllingPersonBeneficialOwnerService {
	static async getBeneficialOwner(controllingPersonId: string, beneficialOwnerId: string): Promise<Shared.IBeneficialOwner> {
		return UnauthorizedService.authorizedFetch("api/ControllingPersonBeneficialOwner?controllingPersonId=" + controllingPersonId + "&beneficialOwnerId=" + beneficialOwnerId, {
			method: "get",
			headers: {
				"Content-type": "application/json"
			}
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}

	static async saveBeneficialOwner(beneficialOwner: Shared.IBeneficialOwner) {
		if (beneficialOwner.id !== '') {
			return await this.updateBeneficialOwner(beneficialOwner);
		}
		else {
			return await this.addBeneficialOwner(beneficialOwner);
		}
	}

  
  private static async addBeneficialOwner(beneficialOwner: Shared.IBeneficialOwner) {
		const response = await UnauthorizedService.authorizedFetch("api/ControllingPersonBeneficialOwner", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(beneficialOwner)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	private static async updateBeneficialOwner(beneficialOwner: Shared.IBeneficialOwner) {
		const response = await UnauthorizedService.authorizedFetch("api/ControllingPersonBeneficialOwner/update", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(beneficialOwner)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async delete(id: string, controllingPersonId: string) {
		const response = await UnauthorizedService.authorizedFetch("/api/ControllingPersonBeneficialOwner?id=" + id + "&controllingPersonId=" + controllingPersonId, {
			method: 'delete',
			headers: {
				"Content-type": "application/json"
			}
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}
}