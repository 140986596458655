import React from "react";
import { Formik, Form, Field } from 'formik';
import DateFnsUtils from "@date-io/date-fns";
import { TextFieldMaples, SelectMaples } from 'msg-react-lib';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Shared from '../../../shared/sharedTypes';
import { GlobalService } from '../../../services/globalService';
import styles from './beneficialOwnerDetails.module.scss';
import * as Yup from "yup";

export default function BeneficialOwnerDetails(props: Shared.IEntityProps) {

	const bagRef = React.useRef<any>();
	const notificationField = props.activeNotification?.notificationKey && props.activeNotification?.notificationKey.charAt(0).toLowerCase() + props.activeNotification?.notificationKey.slice(1);
	let validationSchema = props.activeNotification ? Yup.object().shape({
		[notificationField]: Yup.string().required(props.activeNotification?.description)
	}) : null;

	React.useEffect(() => {
		bagRef?.current.setFieldTouched(notificationField, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.activeNotification])

	function handleSubmit(values: Shared.IBeneficialOwner) {
		if (values.name !== '' && values.line1 !== '' && values.countryCode !== '') {
			props.setBoDetails!(values);		
		} else {
			props.bindGetNextStepHandler!(() => { })
			GlobalService.sendSnack("error", "Required fields are missed");
		}
	}

	return (
		<>
			<div className="stepContent">
				<h1>Beneficial Owner Details</h1>
				<div className={`stepLeft`}>
					<h2>Please confirm Beneficial Owner details</h2>
				</div>
				<Formik enableReinitialize validationSchema={validationSchema} initialValues={props.beneficialOwner!} onSubmit={handleSubmit} innerRef={bagRef}>
					{
						(formikProps) => {
							props.bindSubmitFormHandler!(formikProps.submitForm);
							props.setFormikProps!(formikProps);
							return (
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Form className="stepRight stepDetails">
										<div className={`rowWrap`}>
											<Field
												autoFocus
												name="name"
												component={TextFieldMaples}
												label="Beneficial Owner Name"
												required
												style={{ marginBottom: 0 }}
											/>
											<div className={styles.twoColumn}>
												<Field
													name="nationality"
													label="Nationality"
													options={props.countries!.map((country) => {
														return (
															{ value: country.code, label: country.name }
														)
													})}
													component={SelectMaples}
												/>
												<Field
													name="birthCountry"
													label="Country of Birth"
													options={props.countries!.map((country) => {
														return (
															{ value: country.code, label: country.name }
														)
													})}
													component={SelectMaples}
												/>
											</div>
											<Field
												required
												name="line1"
												component={TextFieldMaples}
												label="Line1"
												style={{ marginBottom: 0 }}
											/>
											<Field
												name="line2"
												component={TextFieldMaples}
												label="Line2"
												style={{ marginBottom: 0 }}
											/>
											<div className={styles.twoColumn}>
												<Field
													name="city"
													component={TextFieldMaples}
													label="City"
													style={{ marginBottom: 0 }}
												/>
												<Field
													name="state"
													component={TextFieldMaples}
													label="State"
													style={{ marginBottom: 0 }}
												/>
											</div>
											<div className={styles.twoColumn}>
												<Field
													name="zip"
													component={TextFieldMaples}
													label="Zip"
												/>
												<Field
													required
													name="countryCode"
													label="Country"
													options={props.countries!.map((country) => {
														return (
															{ value: country.code, label: country.name }
														)
													})}
													component={SelectMaples}
												/>
											</div>
										</div>
									</Form>
								</MuiPickersUtilsProvider>
							)
						}
					}
				</Formik>
			</div>
		</>
	);
}