import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { TextFieldMaples } from 'msg-react-lib';
import { Formik, Form, Field } from "formik";
import Button from '@material-ui/core/Button';
import { AccountService } from '../services/accountService';

export default function ForgotPasswordInitiate() {
	const [showError, setShowError] = useState(false);
	const history = useHistory();
	const formInitialValues = {
		userId: ''
	}

	const handleSubmit = async (values) => {
		let success = await AccountService.checkAccountRecoveryUsername({ username: values.userId });
		if (success) {
			history.push({
				pathname: '/forgotPasswordVerify',
				state: { id: success.id, userId: values.userId }
			});
		}
		else {
			setShowError(true);
		}
	};

	return (
		<div>
			<h1>Forgot Password?</h1>
			{showError ? <h3>Error occured. Please try again after 5 minutes.</h3> : null}
			<Formik enableReinitialize initialValues={formInitialValues} onSubmit={handleSubmit}>
				{
					({ dirty }) => (
						<Form>
							<Field name="userId" component={TextFieldMaples} label="User ID" required />
							<Button type="submit" color="primary" variant="contained" disabled={!dirty}>Continue</Button>
						</Form>
					)
				}
			</Formik>
		</div>
	);
}