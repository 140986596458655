import React from 'react';
import styles from './entityChart.module.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Node from './entityChartNode';

export default function SecondLevelBeneficialOwnerSection(props) {

	let {
		dialogOpen,
		setUboGuideDialogOpen,
		controllingPersonBeneficialOwnerDialogOpen,
		addControllingPersonBeneficialOwner,
		addControllingPersonBOStatus,
		animationInTime,
		animationOutTime,
		onAnimationFinished,
		editControllingPersonBO,
		confirmDeleteControllingPersonBeneficialOwner,
		subNodeClicked,
		isChildActive,
		activeControllingPerson,
		activeChild,
		showConnectors
	} = props;

	return (
		<>
			<div className={`${styles.section} ${styles.bottomSection} ${styles.uboSection}`} id="column3ubo">
				<div className={styles.titleSection}>
					<h3>Beneficial Owner(s)</h3>
					<div className={styles.buttonBar}>
						<button className="iconButton" onClick={() => setUboGuideDialogOpen(true)}>?</button>
						{
							activeControllingPerson.declareHavingUbo ?
								<button className="iconButton" onClick={() => addControllingPersonBeneficialOwner(activeControllingPerson)}>+</button>
								: null
						}
					</div>
				</div>
				<h4><span>{activeControllingPerson.beneficialOwners?.length || 0}</span> added</h4>
				<p className={styles.sectiondescription}>All owners who own 10% or more required</p>
				<div className={styles.nodes}>
					{
						!activeControllingPerson.declareHavingUbo ?
							(
								activeControllingPerson.declareHavingUbo === null ?
									<Node text="Beneficial Owner Declaration" declaration={true} empty={true} click={() => addControllingPersonBOStatus(activeControllingPerson)}></Node>
									:
									<Node text="Beneficial Owner Declaration" click={() => addControllingPersonBOStatus(activeControllingPerson)} status="Completed" section="ubo" details={{ name: "Confirmed no BO's" }} />
							)
							:
							<>
								{
									activeControllingPerson.beneficialOwners.length === 0 && <button className={`${styles.buttonText} declarationButton`} onClick={() => addControllingPersonBOStatus(activeControllingPerson)}> Change Beneficial Owner Declaration</button>
								}

								<TransitionGroup component={null} enter={!showConnectors} exit={!showConnectors}>
									{
										activeControllingPerson.beneficialOwners.map((bo) => (
											<CSSTransition
												key={bo.id}
												timeout={{ enter: animationInTime, exit: animationOutTime }}
												classNames={'cpNode'}
												onExited={onAnimationFinished}
											>
												<Node
													key={bo.id}
													editClick={() => editControllingPersonBO(activeControllingPerson, bo)}
													deleteClick={() => confirmDeleteControllingPersonBeneficialOwner(activeControllingPerson, bo)}
													click={subNodeClicked}
													isActive={isChildActive(bo.id)}
													details={bo}
													section="ubo"
													status={bo.status}
													dialogOpen={dialogOpen}
												/>
											</CSSTransition>
										))
									}
								</TransitionGroup>
								<CSSTransition
									in={activeControllingPerson.beneficialOwners.length === 0}
									timeout={{ enter: animationInTime, exit: animationOutTime }}
									classNames={'cpNode'}
									unmountOnExit={true}
									onExited={onAnimationFinished}
								>
									<Node key={`uboEmpty`} empty={true} isActive={controllingPersonBeneficialOwnerDialogOpen && !activeChild.id ? true : false} section="ubo" click={() => addControllingPersonBeneficialOwner(activeControllingPerson)}></Node>
								</CSSTransition>
							</>
					}
				</div>
			</div>
		</>
	)
}