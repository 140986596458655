export class AccountService {
	static async signIn(credentials) {
		let success = false;
		let response = await fetch('api/account/', {
			method: "post",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(credentials)
		});

		if (response.status === 200) {
			let data = await response.json();
			localStorage.setItem("jwtToken", data.jwtToken);
			success = true;
		}
		return success;
	}

	static async completeSignUp(credentials) {
		let success = false;
		let response = await fetch('api/account/completeSignup', {
			method: "post",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(credentials)
		});

		if (response.status === 200) {
			let data = await response.json();
			localStorage.setItem("jwtToken", data.jwtToken);
			success = true;
		}
		return success;
	}

	static async checkAccountRecoveryUsername(recoverAccountRequest) {
		let success = false;
		let response = await fetch('api/account/checkAccountRecoveryUsername', {
			method: "post",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(recoverAccountRequest)
		});

		if (response.status === 200) {
			let data = await response.json();
			success = true;
			return data;
		}
		return success;
	}

	static async checkRecoveryCode(recoverCodeRequest) {
		let success = false;
		let response = await fetch('api/account/checkRecoveryCode', {
			method: "post",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(recoverCodeRequest)
		});

		if (response.status === 200) {
			let data = await response.json();
			success = true;
			return data;
		}
		return success;
	}

	static async checkPasswordReset(passwordResetRequest) {
		let success = false;
		let response = await fetch('api/account/checkPasswordReset', {
			method: "post",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(passwordResetRequest)
		});

		if (response.status === 200) {
			let data = await response.json();
			localStorage.setItem("jwtToken", data.jwtToken);
			success = true;
		}
		return success;
	}

}