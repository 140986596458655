import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Shared from '../../../../shared/sharedTypes'
import { SecondLevelControllingPersonService } from '../../../../services/secondLevelControllingPersonService'
import { ControllingPersonService } from '../../../../services/controllingPersonService'
import { TextFieldMaples } from 'msg-react-lib';
import { GlobalService } from '../../../../services/globalService';

export class IndividualProfile extends Component<Shared.IEntityProps> {
	constructor(props: Shared.IEntityProps) {
		super(props)
		this.setNextActiveStep = this.setNextActiveStep.bind(this);
		this.setBackActiveStep = this.setBackActiveStep.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	async setNextActiveStep() {
		this.props.setActiveStep!(1);
	}

	async setBackActiveStep() {
		this.props.setActiveStep!(0);
	}

	async handleSubmit(values: Shared.ISecondLevelControllingPerson) {
		await SecondLevelControllingPersonService.save(values)
			.then(async result => {
				if (result.id !== undefined) {
					await this.props.updateEntityInvestor!();
					await this.props.updateSecondLevelControllingPerson!(result);
					this.setNextActiveStep();
				} else if (result.Name !== undefined) {
					GlobalService.sendSnack("error", result.Name)
				} else if (!result.success) {
					GlobalService.sendSnack("error", "Second level controlling person name is required")
				}
			})
	}

	public render() {
		return (
			<div className="stepContent">
				<h1>{ControllingPersonService.getControllingPersonType(this.props.controllingPerson!.type)}</h1>
				<div className="stepLeft">
					<div>
						<h3 className="stepCount">{`Step ${this.props.activeStep}`}</h3>
						<h2>Please share your details</h2>
					</div>
				</div>
				<Formik enableReinitialize initialValues={this.props.secondLevelControllingPerson!} onSubmit={this.handleSubmit}>
					{
						(formikProps) => {
							this.props.bindSubmitFormHandler!(formikProps.submitForm);
							this.props.setFormikProps!(formikProps);
							return (
								<Form className={`stepRight stepDetails`}>
									<div >
										<Field
											autoFocus
											name="name"
											component={TextFieldMaples}
											label="Name"
											className="col-1"
										/>
									</div>
								</Form>
							)
						}
					}
				</Formik>
			</div>
		);
	}
}