import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Shared from '../../shared/sharedTypes';
import { InvestorService } from '../../services/investorService';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextFieldMaples, SelectMaples, DatePickerMaples } from 'msg-react-lib';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import styles from './profile.module.scss';
import { GlobalService } from '../../services/globalService';
import MessageDialog from '../../shared/dialogs/dialogMessage';
import { ValidationService } from '../../services/validationService';
import * as Yup from "yup";

class State {
	investor: Shared.IEntityInvestor = Shared.EmptyEntityInvestor;
	guideDialogOpen: boolean = false;
}

export class EntityProfile extends Component<Shared.IEntityProps, State> {
	state = new State();
	bagRef;
	notificationField:string;
	validationSchema;

	constructor(props: Shared.IEntityProps) {
		super(props);
		this.state = {
			investor: (this.props.entityInvestor !== undefined) ? this.props.entityInvestor : Shared.EmptyEntityInvestor,
			guideDialogOpen: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleGuideDialogClose = this.toggleGuideDialogClose.bind(this);
		this.bagRef = React.createRef();
		this.notificationField = this.props.activeNotification?.notificationKey && this.props.activeNotification?.notificationKey.charAt(0).toLowerCase() + this.props.activeNotification?.notificationKey.slice(1);
		this.validationSchema = this.props.activeNotification ? Yup.object().shape({
			[this.notificationField]: Yup.string().required(this.props.activeNotification?.description)
		}) : null;
	}

	componentDidMount() {
		this.bagRef.current.setFieldTouched(this.notificationField, true);
	}

	async handleSubmit(values: Shared.IEntityInvestor) {
		await InvestorService.update(values)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					await ValidationService.validateInvestor('InvestorProfile')
						.then(result => {
							GlobalService.sendNotification(result)
						})
					await this.props.updateEntityInvestor!();
				}
			});
	}

	toggleGuideDialogClose() {
		this.setState({
			guideDialogOpen: !this.state.guideDialogOpen
		})
	}

	public render() {
		return (
			
			<div className="stepContent">
				<h1>Investor Profile</h1>
				<div className="stepLeft">
					<div>
						<h3 className="stepCount">{`Step ${this.props.activeStep}`}</h3>
						<h2>Please confirm your details</h2>
					</div>
				</div>
				
				<Formik validationSchema={this.validationSchema} enableReinitialize initialValues={this.state.investor} onSubmit={this.handleSubmit} innerRef={this.bagRef} initialTouched={{}}>
					{
						(formikProps) => {
							this.props.bindSubmitFormHandler!(formikProps.submitForm);
							this.props.setFormikProps!(formikProps);
							return (
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Form className="stepRight stepDetails">
										<div className={`rowWrap`}>
											<Field
												name="name"
												component={TextFieldMaples}
												style={{ marginBottom: 0 }}
												label="Investor Name"
												disabled
											/>
											<Field autoFocus name="formationDate" style={{ marginBottom: 0 }} label="Date of formation" className={`${styles.date} ${styles.twoColumn}`} component={DatePickerMaples} inputVariant="filled" />
											<Field
												name="businessPrincipalPlace"
												label="Principal Place of Business"
												options={this.props.countries!.map((country) => {
													return (
														{ value: country.code, label: country.name }
													)
												})}
												component={SelectMaples}
											/>
											<Field
												name="registrationCountry"
												label="Country of Registration"
												options={this.props.countries!.map((country) => {
													return (
														{ value: country.code, label: country.name }
													)
												})}
												component={SelectMaples}
											/>

											<div className={styles.twoColumn}>
												<Field
													name="phone"
													component={TextFieldMaples}
													label="Phone"
												/>
												<Field
													name="phoneAlternative"
													component={TextFieldMaples}
													label="Phone Alternative"
												/>
											</div>
											<div className={styles.pepButton}>
												<FormControlLabel
													style={{ marginLeft: '0' }}
													value="isPep"
													label="Tick this box if a person who has a beneficial ownership or controlling interest in the Investor Entity is a Politically Exposed Person - click on the information icon for more information"
													labelPlacement="end"
													control={
														<Field name="isPep">
															{({
																field
															}) => (
																<Checkbox color="primary" name="isPep" checked={!!field.value} {...field} />
															)}
														</Field>
													} />
												<button type="button" onClick={() => { this.toggleGuideDialogClose() }} className="iconTextButton icon-info mediumIcon"></button>
											</div>
										</div>
									</Form>
								</MuiPickersUtilsProvider>
							)
						}
					}
				</Formik>
				<MessageDialog
					isOpen={this.state.guideDialogOpen}
					handleClose={this.toggleGuideDialogClose}
					title="Definition of a PEP"
					description={<>a) a person who is or has been entrusted with prominent public functions by a foreign (non-Cayman Islands) country, for example a Head of State or of government, senior politician, senior government, judicial or military official, senior executive of a state owned corporation, and important political party official; <br />
(b) 	a person who is or has been entrusted domestically (in the Cayman Islands) with prominent public functions, for example a Head of State or of government, senior politician, senior government, judicial or military official, senior executives of a state owned corporation and important political party official; and <br />
(c) 	a person who is or has been entrusted with a prominent function by an international organisation like a member of senior management, such as a director, a deputy director and a member of the board or equivalent functions</>}
					cancelText="Cancel"
					confirmText="Delete"
				/>
			</div>
		);
	}
}