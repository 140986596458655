import React, { Component } from 'react';
import * as Shared from '../../shared/sharedTypes'
import { Document } from '../../shared/documentUpload/documentComponent';
import { GlobalService } from '../../services/globalService';
import { DocumentService } from '../../services/documentService'
import { InvestorService } from '../../services/investorService'
import MessageDialog from '../../shared/dialogs/dialogMessage';
import { ValidationService } from '../../services/validationService';

const styles = {
  document: {
    marginBottom: "20px"
  }
}
const fileUrl = require('../../files/Self_Certification_Form.docx');

class State {
  guideTitle = ''
  guideDescription = ''
  guideDialogOpen = false
  selfCertificate: Shared.IDocument = Shared.EmptyDocument
}

export class FatcaCrs extends Component<Shared.IProps> {
  state = new State();
  constructor(props) {
    super(props)
    this.loadDocuments = this.loadDocuments.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
    this.clearDocument = this.clearDocument.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.validateInvestor = this.validateInvestor.bind(this);
  }

  componentDidMount() {
    this.loadDocuments();
  }

  private async loadDocuments() {
    var document = this.props.investor!.documents.find(x => x.type === 'FatcaCrsSelfCertificate')
    if (document !== undefined) {
      document.title = 'Self Certificate'
      this.setState({ selfCertificate: document })
    } else {
      this.setState({
        selfCertificate: {
          title: "Self Certificate",
          type: 'FatcaCrsSelfCertificate',
          id: 0,
          name: '',
          notes: '',
          content: null,
          guide: ''
        }
      })
    }
  }

  async saveDocument(values: Shared.IDocument) {
    await DocumentService.saveDocument(values)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);          
          await this.validateInvestor();
          await this.populateInvestorData();
          await this.loadDocuments();
        }
      });
  }

  async uploadDocument(values: Shared.IDocument) {
    if (values.content.files[0] === undefined) {
      return
    }
    const formData = new FormData();
    formData.append('name', values.content.files[0].name);
    formData.append('type', values.type);
    formData.append('content', values.content.files[0]);
    await DocumentService.uploadDocument(formData)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);          
          await this.validateInvestor();
          await this.populateInvestorData();
          await this.loadDocuments();
        }
      });
  }

  async clearDocument(values: Shared.IDocument) {
    await DocumentService.clearDocument(values.id)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);          
          await this.validateInvestor();
          await this.populateInvestorData();
          await this.loadDocuments();
        }
      });
  }

  async populateInvestorData() {
    await InvestorService.getInvestor()
      .then(async result => {
        await this.props.updateInvestor(result);        
      })
  };

  async handleNext() {
    await this.validateInvestor();
  }

  async validateInvestor() {
    await ValidationService.validateInvestor('Fatca/Crs', true)
      .then(result => {
        GlobalService.sendNotification(result)
      })
  }

  render() {
    return (
      <>
        {this.props.bindSubmitFormHandler!(this.handleNext)}
        <div className="stepContent">
          <h1>FATCA/CRS</h1>
          <div className={`stepDetails`}>
            <div>
              <h3 className="stepCount">{`Step ${this.props.activeStep}`}</h3>
              <h2>FATCA/CRS Self Certification</h2>
              <article>
                <p>To comply with reporting obligations under FATCA and CRS, please submit a completed Self-Certificate in the place provided. If you do not have a Self-Certificate previously completed, feel free to download a template via the link provided.:</p>
              </article>
            </div>
          </div>
          <div className={`stepRight stepDetails`}>
            <div className="rowWrap">
              <div style={styles.document}>
                <Document
                  document={this.state.selfCertificate}
                  uploadDocument={this.uploadDocument}
                  saveDocument={this.saveDocument}
                  clearDocument={this.clearDocument} />
              </div>
              <div style={styles.document}>
                <a href={fileUrl} className="downloadButton" download="Self Certification.docx">Download Self Certificate Form</a>
              </div>
            </div>
          </div>
        </div>
        <MessageDialog
          isOpen={this.state.guideDialogOpen}
          handleClose={() => { this.setState({ guideDialogOpen: !this.state.guideDialogOpen }) }}
          title={this.state.guideTitle}
          description={this.state.guideDescription}
          cancelText="Cancel"
          confirmText="Delete"
        />
      </>
    );
  }
}