import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Shared from '../../shared/sharedTypes';
import { InvestorService } from '../../services/investorService';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextFieldMaples, SelectMaples, DatePickerMaples } from 'msg-react-lib';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import styles from './profile.module.scss';
import MessageDialog from '../../shared/dialogs/dialogMessage';
import { ValidationService } from '../../services/validationService';
import { GlobalService } from '../../services/globalService';
import * as Yup from "yup";

export class InvestorProfile extends Component<Shared.IProps, Shared.IInvestorState> {
	bagRef;
	notificationFields: Array<string> = [];
	validationSchema;
	schemaObject = {};

	constructor(props: Shared.IProps) {
		super(props);
		this.state = {
			investor: (this.props.investor !== undefined) ? this.props.investor : Shared.EmptyIndividualInvestor,
			guideDialogOpen: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleGuideDialogClose = this.toggleGuideDialogClose.bind(this);
		this.bagRef = React.createRef();
		props.activeNotification?.forEach((notification) => {
			const notificationField = notification?.notificationKey && notification?.notificationKey.charAt(0).toLowerCase() + notification?.notificationKey.slice(1);
			this.notificationFields.push(notificationField)
			this.schemaObject[notificationField] = Yup.string().required(notification?.description);
		})
		this.validationSchema = Yup.object().shape(this.schemaObject);
	}

	componentDidMount() {
		this.notificationFields.forEach((notification) => {
			this.bagRef.current.setFieldTouched(notification, true);
		})

	}

	async handleSubmit(values: Shared.IInvestor) {
		await InvestorService.update(values).then(async response => {
			if (response.errors !== undefined) {
				Object.keys(response.errors).map((key) => (
					GlobalService.sendSnack('error', `${response.errors[key]}`)
				));
			}
			else {
				await ValidationService.validateInvestor('InvestorProfile')
					.then(result => {
						this.props.setCaseIsValid(result.totalIssues === 0)
						GlobalService.sendNotification(result)
					})
			}
		});
		await this.props.updateInvestor(values);
	}

	toggleGuideDialogClose() {
		this.setState({
			guideDialogOpen: !this.state.guideDialogOpen
		})
	}

	removeTrailingCharIfFound(str: string): string {
		let lastChar = str.slice(-1);
		let nonNumericRegex = /[^0-9.]+/g;
		if (nonNumericRegex.test(lastChar)) {
			return str.slice(0, -1);
		} else {
			return str;
		}
	}

	formatValue(str: string): string {
		let unmaskedValue = str.replace(/\D+/g, '');
		let formatted = `+${unmaskedValue}`
		return this.removeTrailingCharIfFound(formatted);
	}

	public render() {
		return (
			<div className="stepContent">
				<h1>Investor Profile</h1>
				<div className={`stepLeft`}>
					<div>
						<h3 className="stepCount">{`Step ${this.props.currentStep}`}</h3>
						<h2>Please confirm your details</h2>
					</div>
				</div>

				<Formik validationSchema={this.validationSchema} enableReinitialize initialValues={this.state.investor} onSubmit={this.handleSubmit} innerRef={this.bagRef} initialTouched={{}}>
					{
						(formikProps) => {
							this.props.bindSubmitFormHandler!(formikProps.submitForm);
							return (
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<Form className="stepRight stepDetails">
										<div className={`rowWrap`}>
											<Field
												name="name"
												component={TextFieldMaples}
												label="Investor Name"
												disabled
												style={{ marginBottom: 0 }}
											/>
											<Field autoFocus name="birthDate" style={{ marginBottom: 0 }} label="Birth Date" className={`${styles.date} ${styles.twoColumn}`} component={DatePickerMaples} inputVariant="filled" />
											<Field
												name="occupation"
												component={TextFieldMaples}
												label="Occupation"
												style={{ marginBottom: 0 }}
											/>
											<div className={styles.twoColumn}>
												<Field
													name="nationality"
													label="Nationality"
													options={this.props.countries!.map((country) => {
														return (
															{ value: country.code, label: country.name }
														)
													})}
													component={SelectMaples}
												/>
												<Field
													name="birthCountry"
													label="Birth Country"
													options={this.props.countries!.map((country) => {
														return (
															{ value: country.code, label: country.name }
														)
													})}
													component={SelectMaples}
												/>
											</div>
											<div className={styles.twoColumn}>
												<Field
													name="phone"
													style={{ marginBottom: 0 }}
												>
													{(fieldProps) => (
														<TextFieldMaples
															{...fieldProps}
															label="Phone"
															onChange={event => {
																fieldProps.field.onChange(event.target.name)(
																	this.formatValue(event.target.value)
																);
															}}

														/>
													)}
												</Field>
												<Field
													name="phoneAlternative"
													style={{ marginBottom: 0 }}
												>
													{(fieldProps) => (
														<TextFieldMaples
															{...fieldProps}
															label="Phone Alternative"
															onChange={event => {
																fieldProps.field.onChange(event.target.name)(
																	this.formatValue(event.target.value)
																);
															}}
														/>
													)}
												</Field>
											</div>
											<div className={styles.pepButton}>
												<FormControlLabel
													style={{ marginLeft: '-9' }}
													value="isPep"
													label="Do you meet the definition of a Politically Exposed Person?"
													labelPlacement="end"
													control={
														<Field name="isPep">
															{({
																field
															}) => (
																<Checkbox color="primary" name="isPep" checked={!!field.value} {...field} />
															)}
														</Field>
													} />
												<button type="button" onClick={() => { this.toggleGuideDialogClose() }} className="iconTextButton icon-info mediumIcon"></button>
											</div>
										</div>
									</Form>
								</MuiPickersUtilsProvider>
							)
						}
					}
				</Formik>
				<MessageDialog
					isOpen={this.state.guideDialogOpen}
					handleClose={this.toggleGuideDialogClose}
					title="Definition of a PEP"
					description={<>a) a person who is or has been entrusted with prominent public functions by a foreign (non-Cayman Islands) country, for example a Head of State or of government, senior politician, senior government, judicial or military official, senior executive of a state owned corporation, and important political party official; <br />
(b) 	a person who is or has been entrusted domestically (in the Cayman Islands) with prominent public functions, for example a Head of State or of government, senior politician, senior government, judicial or military official, senior executives of a state owned corporation and important political party official; and <br />
(c) 	a person who is or has been entrusted with a prominent function by an international organisation like a member of senior management, such as a director, a deputy director and a member of the board or equivalent functions</>}
					cancelText="Cancel"
					confirmText="Delete"
				/>
			</div>
		);
	}
}