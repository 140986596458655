import React from 'react';
import styles from './entityChart.module.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ControllingPersonService } from '../services/controllingPersonService';
import Node from './entityChartNode';

export default function ControllingPersonsSection(props) {

	let {
		dialogOpen,
		setDirectorsGuideDialogOpen,
		addControllingPerson,
		animationInTime,
		animationOutTime,
		onAnimationFinished,
		editControllingPerson,
		confirmDeleteControllingPerson,
		nodeClicked,
		isActive,
		activeControllingPerson,
		activeNotification,
		setActiveNotification,
		investor
	} = props;

	return (
		<>
			<div className={`${styles.section} ${styles.cpSection}`} id="directorsEl">
				<div className={styles.titleSection}>
					<h3>{ControllingPersonService.getControllingPersonsListTitle(investor.investorType)}</h3>
					<div className={styles.buttonBar}>
						<button className="iconButton" onClick={() => setDirectorsGuideDialogOpen(true)}>?</button>
						<button className="iconButton" onClick={addControllingPerson}>+</button>
					</div>
				</div>
				<h4><span>{investor.controllingPersons.length}</span> added</h4>
				{investor.controllingPersons.length < 2 ? <p className={styles.sectiondescription}>Minimum of 2 records required</p> : null}

				<div className={styles.nodes}>
					<TransitionGroup component={null}>
						{

							investor.controllingPersons.map((cp) =>
							(
								<CSSTransition
									key={cp.id}
									timeout={{ enter: animationInTime, exit: animationOutTime }}
									classNames={'cpNode'}
									onExited={onAnimationFinished}
								>
									<Node
										editClick={editControllingPerson}
										deleteClick={confirmDeleteControllingPerson}
										click={nodeClicked}
										isActive={isActive(cp.id)}
										details={cp}
										className={styles.active}
										section="cp"
										id={activeControllingPerson.id === cp.id ? 'column2source' : ''}
										status={cp.status}
										dialogOpen={dialogOpen}
									/>
								</CSSTransition>
							)
							)
						}
						{
							investor.controllingPersons.length < 2 ?

								Array.from(Array(2 - investor.controllingPersons.length), (e, i) => {
									return <CSSTransition
										key={`cpEmpty_${i}`}
										timeout={{ enter: animationInTime, exit: animationOutTime }}
										classNames={'cpEmptyNode'}
										onExited={onAnimationFinished}
									>
										<Node dialogOpen={dialogOpen} empty={true} click={addControllingPerson} isActive={dialogOpen && !activeControllingPerson.id && i === 0 ? true : false} section="cp" activeNotification={activeNotification} setActiveNotification={setActiveNotification}></Node>
									</CSSTransition>
								})
								: null
						}
					</TransitionGroup>
				</div>
			</div>
		</>
	)
}