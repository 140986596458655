import React from 'react';
import styles from './entityChart.module.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ControllingPersonService } from '../services/controllingPersonService';
import Node from './entityChartNode';

export default function SecondLevelControllingPersonsSection(props) {

	let {
		dialogOpen,
		setSecondLevelDirectorsGuideDialogOpen,
		secondLevelCPDialogOpen,
		addSecondLevelCP,
		animationInTime,
		animationOutTime,
		onAnimationFinished,
		editSecondLevelControllingPerson,
		confirmDeleteSecondLevelControllingPerson,
		subNodeClicked,
		isChildActive,
		activeControllingPerson,
		activeChild,
		showConnectors
	} = props;

	return (
		<>
			<div className={`${styles.section} ${styles.ccpSection}`} id="column3cp">
				<div className={styles.titleSection}>
					<h3>{ControllingPersonService.getControllingPersonsListTitle(activeControllingPerson.type)}</h3>
					<div className={styles.buttonBar}>
						<button className="iconButton" onClick={() => setSecondLevelDirectorsGuideDialogOpen(true)}>?</button>
						<button className="iconButton" onClick={() => addSecondLevelCP(activeControllingPerson)}>+</button>
					</div>
				</div>
				<h4><span>{activeControllingPerson.secondLevelControllingPersons.length}</span> added</h4>
				<p className={styles.sectiondescription}>Minimum of 2 records required</p>
				<div className={styles.nodes}>
					<TransitionGroup component={null} enter={!showConnectors} exit={!showConnectors}>
						{
							activeControllingPerson.secondLevelControllingPersons.map((cp) => (
								<CSSTransition
									key={cp.id}
									timeout={{ enter: animationInTime, exit: animationOutTime }}
									classNames={'cpNode'}
									onExited={onAnimationFinished}
								>
									<Node
										editClick={editSecondLevelControllingPerson}
										deleteClick={confirmDeleteSecondLevelControllingPerson}
										click={subNodeClicked}
										isActive={isChildActive(cp.id)}
										details={cp}
										section="ccp"
										status={cp.status}
										dialogOpen={dialogOpen}
									/>
								</CSSTransition>
							))
						}

						{
							activeControllingPerson.secondLevelControllingPersons.length < 2 ?

								Array.from(Array(2 - activeControllingPerson.secondLevelControllingPersons.length), (e, i) => {
									return <CSSTransition
										key={`ccpEmpty_${i}`}
										timeout={{ enter: animationInTime, exit: animationOutTime }}
										classNames={'cpEmptyNode'}
										onExited={onAnimationFinished}
									>
										<Node dialogOpen={dialogOpen} empty={true} click={() => addSecondLevelCP(activeControllingPerson)} isActive={secondLevelCPDialogOpen && !activeChild.id && i === 0 ? true : false} section="ccp"></Node>
									</CSSTransition>
								})
								: null
						}
					</TransitionGroup>
				</div>
			</div>
		</>
	)
}