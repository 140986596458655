export interface IInvestor {
	name: string;
	investorType: string;
	typeDiscriminator: string;
	type?: string;
	riskRating: string;
	phone: string;
	phoneAlternative: string;
	sourceOfFunds: string;
	dataProtectionAccepted: boolean;
	dataProtectionAcceptanceDate: Date | string;
	dueDiligenceConsent: string;
	isPep: boolean;
	investorAddress: Array<IInvestorAddress>;
	documents: IDocument[];
	highRiskInvestor: boolean;
	requiredDocuments: Array<IRequiredDocument>;
	mainWorkflowCompleted: boolean | null;
}

export interface IIndividualInvestor extends IInvestor {
	birthDate: Date | string | null;
	occupation: string;
	nationality: string;
	birthCountry: string;
	usCitizenshipStatus: string;
	taxDetails: Array<ITaxDetail>;
	fatcaCrsInScope: boolean;
}

export interface IEntityInvestor extends IInvestor {
	formationDate: Date | string | null;
	businessPrincipalPlace: string;
	registrationCountry: string;
	declareHavingUbo: boolean | null;
	controllingPersons: Array<IControllingPerson>;
	beneficialOwners: Array<IBeneficialOwner>;
	mainWorkflowCompleted: boolean | null;
	status: string;
}

export interface IInvestorAddress {
	id: number,
	type: number | string,
	name: string,
	line1: string,
	line2: string,
	city: string,
	state: string,
	zip: string,
	countryCode: string
}

export interface IControllingPerson {
	id: string;
	name: string;
	type: string;
	regulatedEntity: boolean | null;
	requiredDocuments: Array<IRequiredDocument>;
	documents: IDocument[];
	declareHavingUbo: string;
	beneficialOwners: Array<IBeneficialOwner>;
	secondLevelControllingPerson: Array<ISecondLevelControllingPerson>;
	status: string;
}

export interface IBeneficialOwner {
	id: string;
	documents: Array<IDocument>;
	controllingPersonId: string;
	name: string;
	nationality: string;
	birthCountry: string;
	line1: string;
	line2: string;
	city: string;
	state: string;
	zip: string;
	countryCode: string;
	status: string;
}

export interface ISecondLevelControllingPerson {
	controllingPersonId: string;
	name: string;
	id: string;
	documents: IDocument[];
	status: string;
}

export interface IDocument {
	id: number;
	controllingPersonId: string;
	beneficialOwnerId: string;
	secondLevelControllingPersonId: string;
	title: string;
	name: string;
	type: string;
	notes: string;
	guide: string;
	content: any;
	ref?: any;
}

export interface ITaxDetail {
	id: number,
	countryCode: string,
	taxReferenceNumberType: string,
	taxIdentificationNumber: string,
	taxDetailsNotApplicable: boolean
}

export interface ICountry {
	name: string,
	code: string
}

export interface IProps {
	investor?: IInvestor;
	activeStep?: number;
	currentStep?: number;
	steps?: number;
	countries?: ICountry[];
	highRiskJurisdictions?: ICountry[];
	setActiveStep?(stepNumber: number);
	updateInvestor(investor: IInvestor);
	bindSubmitFormHandler?(submitFormHandler);
	bindGetNextStepHandler?(getNextStepHandler);
	setFormikProps?(formikProps);
	setCaseIsValid(isValid: boolean);
	activeNotification?:any;
}

export interface IInvestorState {
	investor: IInvestor,
	isChecked?: boolean,
	guideDialogOpen?: boolean
}

export interface IEntityProps {
	entityInvestor?: IEntityInvestor;
	controllingPerson?: IControllingPerson;
	secondLevelControllingPerson?: ISecondLevelControllingPerson;
	beneficialOwner?: IBeneficialOwner;
	activeStep?: number;
	steps?: number;
	countries?: ICountry[];
	highRiskJurisdictions?: ICountry[];
	setBoDetails?(beneficialOwner: IBeneficialOwner);
	setActiveStep?(stepNumber: number);
	updateEntityInvestor?();
	updateControllingPerson?(controllingPerson: IControllingPerson);
	updateSecondLevelControllingPerson?(secondLevelControllingPerson: ISecondLevelControllingPerson);
	bindSubmitFormHandler?(submitFormHandler);
	bindGetNextStepHandler?(getNextStepHandler);
	bindGetBackStepHandler?(getBackStepHandler);
	handleClose?();
	setFormikProps?(formikProps);
	activeNotification?: any;
}

export interface IRequiredDocument {
	highRiskTitle: string;
	lowRiskTitle: string;
	type: string;
	highRiskGuide: string;
	lowRiskGuide: string;
}

export const EmptyInvestor = {
	name: '',
	investorType: '',
	typeDiscriminator: '',
	riskRating: '',
	phone: '',
	phoneAlternative: '',
	sourceOfFunds: '',
	dataProtectionAccepted: false,
	dataProtectionAcceptanceDate: '',
	dueDiligenceConsent: '',
	isPep: false,
	highRiskInvestor: false,
	investorAddress: [],
	documents: [],
	requiredDocuments: [],
	mainWorkflowCompleted: false
}

export const EmptyIndividualInvestor = {
	...EmptyInvestor,
	birthDate: null,
	occupation: '',
	nationality: '',
	birthCountry: '',
	usCitizenshipStatus: '',
	taxDetails: [],
	fatcaCrsInScope: false
}

export const EmptyEntityInvestor = {
	...EmptyInvestor,
	formationDate: null,
	businessPrincipalPlace: '',
	registrationCountry: '',
	declareHavingUbo: null,
	controllingPersons: [],
	beneficialOwners: [],
	status: ''
}

export const EmptyDocument = {
	id: 0,
	controllingPersonId: '',
	secondLevelControllingPersonId: '',
	beneficialOwnerId: '',
	title: '',
	name: '',
	type: '',
	notes: '',
	guide: '',
	content: null
}

export const EmptyControllingPerson = {
	id: '',
	name: '',
	type: '',
	documents: [],
	regulatedEntity: null,
	requiredDocuments: [],
	declareHavingUbo: '',
	beneficialOwners: [],
	secondLevelControllingPerson: [],
	status: ''
}

export const EmptyBeneficialOwner = {
	id: '',
	controllingPersonId: '',
	documents: [],
	name: '',
	nationality: '',
	birthCountry: '',
	line1: '',
	line2: '',
	city: '',
	state: '',
	zip: '',
	countryCode: '',
	status: ''
}

export const EmptySecondLevelControllingPerson = {
	controllingPersonId: '',
	name: '',
	id: '',
	documents: [],
	status: ''
}

export const EmptyInvestorAddress = {
	id: 0,
	type: '',
	name: '',
	line1: '',
	line2: '',
	city: '',
	state: '',
	zip: '',
	countryCode: ''
}