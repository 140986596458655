import React from 'react';
import Tour from 'reactour';
import ToolTip from './toolTip';
import styles from "../../../assets/styles/toolTips.module.scss";

export default function ToolTipDocUpload(props) {
	const steps = [
		{
			selector: props.selector,
			content: () => (
				<ToolTip toolDetails={{ classes: ["tourleft"], closeTour: () => props.closeTour(), goTo: null, length: 1, stepOn: 1 }}>
					<p>To upload a document, either drag the file onto the box or click the box and select the file</p>
					<p>If no document is available, please include a note of explanation by clicking on 'Add Note' within the box.</p>
				</ToolTip>
			),
			position: 'left',
			action: node => {
				node.focus();
			},
			stepInteraction: false,
			style: {
				top: '-20px'
			},
		}
	]

	return (
		<Tour
			steps={steps}
			isOpen={props.isOpen}
			onRequestClose={props.closeTour}
			rounded={10}
			closeWithMask={false}
			showNavigation={false}
			showButtons={false}
			showCloseButton={false}
			showNumber={false}
			maskSpace={15}
			disableInteraction={true}
			className={styles.tourGuide}
			maskClassName={styles.tourMask}
			highlightedMaskClassName={styles.highlightedElement}
		/>
    );
}