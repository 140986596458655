import React from 'react';
import Button from '@material-ui/core/Button';
import ArrowForward from '@material-ui/icons/ArrowForward';
import styles from './arrowButton.module.scss';

export default function ArrowButton(props) {
	return (
		<Button
			className={`${styles.nextButton} ${props.activeButton ? styles.activeButton : ''}`}
			color="primary"
			variant="outlined"
			onClick={props.handleNext}
		>
			Next
			<ArrowForward className={styles.nextArrowCopy} />
			<ArrowForward className={styles.nextArrow} />
		</Button>
	);
}