import { UnauthorizedService } from './unauthorizedService';

export class CountryService {

  static async getCountriesList() {
    return UnauthorizedService.authorizedFetch('api/countries', null)
      .then(response => {
		  return UnauthorizedService.checkUnauthorized(response).json();
      })
  }
}