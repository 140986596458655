import React, { Component } from 'react';
import * as Shared from '../shared/sharedTypes'
import { InvestorService } from '../services/investorService';
import { GlobalService } from '../services/globalService';
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined';
import styles from './investorAddress.module.scss';
import AddAddress from './investorAddressAdd';
import { ValidationService } from '../services/validationService';

interface IState {
	investor: Shared.IInvestor,
	investorAddress: Shared.IInvestorAddress,
	openAddAddress: boolean,
	addAddressDialogOpen: boolean,
	copiedAddress: Shared.IInvestorAddress,
}

interface IAddressType {
	addressType: string,
	addressTypeLabel: string
}

const addressTypes: IAddressType[] = [
	{ addressType: "Mailing", addressTypeLabel: "Mailing" },
	{ addressType: "Registered", addressTypeLabel: "Registered" },
	{ addressType: "Other", addressTypeLabel: "Other" }
];

export class InvestorAddress extends Component<Shared.IProps, IState> {
	constructor(props: Shared.IProps) {
		super(props);
		this.state = {
			investor: (this.props.investor !== undefined) ? this.props.investor : Shared.EmptyInvestor,
			investorAddress: Shared.EmptyInvestorAddress,
			openAddAddress: false,
			addAddressDialogOpen: false,
			copiedAddress: Shared.EmptyInvestorAddress
		};
		this.addAddressDialogToggle = this.addAddressDialogToggle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.populateInvestorData = this.populateInvestorData.bind(this);
		this.deleteInvestorAddress = this.deleteInvestorAddress.bind(this);
		this.updateInvestorData = this.updateInvestorData.bind(this);
		this.removeInvestorAddressFromInvestor = this.removeInvestorAddressFromInvestor.bind(this);
		this.handleNext = this.handleNext.bind(this);
	}

	getAddressText(address): string {
		if (address.addressType !== "Registered") {
			return address.addressTypeLabel;
		} else {
			return this.props.investor!.type === "Individual" ? "Residential" : "Registered"
		}
	}

	findAddressType(typeToFind) {
		return this.state.investor.investorAddress.find((element) => {
			return element.type === typeToFind;
		})
	}

	private addAddressDialogToggle() {
		this.setState({ addAddressDialogOpen: !this.state.addAddressDialogOpen });
	}

	private async populateInvestorData() {
		await InvestorService.getInvestor()
			.then(result => {
				this.setState({ investor: result });
			})
		await this.props.updateInvestor(this.state.investor);
	};

	private async updateInvestorData(value: Shared.IInvestorAddress) {
		let investor = this.state.investor;
		let addressIndex = investor.investorAddress.findIndex(x => x.id === value.id);
		if (addressIndex !== null && addressIndex !== undefined && addressIndex >= 0) {
			investor.investorAddress[addressIndex] = value;
		}
		this.setState({ investor: investor });
		await this.props.updateInvestor(this.state.investor);
	};

	private async removeInvestorAddressFromInvestor(id: number) {
		let investor = this.state.investor;
		let addressIndex = investor.investorAddress.findIndex(x => x.id === id);
		if (addressIndex !== null && addressIndex !== undefined && addressIndex >= 0) {
			investor.investorAddress.splice(addressIndex, 1)
		}
		this.setState({ investor: investor });
		await this.props.updateInvestor(this.state.investor);
	};

	async deleteInvestorAddress(id: number) {
		await InvestorService.deleteInvestorAddress(id)
			.then(result => {
				GlobalService.sendSnack(result.success ? "success" : "error", result.message)
			});
		await this.removeInvestorAddressFromInvestor(id);
		await this.validate();
		await this.populateInvestorData();
	}

	async editInvestorAddress(address) {
		this.setState({
			investorAddress: address,
			addAddressDialogOpen: true
		});
	}

	async handleSubmit(values: Shared.IInvestorAddress) {
		let convertValueType = { ...values }
		convertValueType.type = addressTypes.map(e => e.addressType).indexOf(values.type.toString());
		if (values.id === 0) {
			await InvestorService.addInvestorAddress(convertValueType)
				.then(result => {
					GlobalService.sendSnack(result.success ? "success" : "error", (result.message ? result.message : "Address not added"))
				});
			await this.validate();
			await this.populateInvestorData();
		}
		else {
			await InvestorService.updateInvestorAddress(values)
				.then(result => {
					GlobalService.sendSnack(result.success ? "success" : "error", (result.message ? result.message : "Address not added"))
				});
			await this.validate();
			await this.updateInvestorData(values);
		}
		this.addAddressDialogToggle();
	}

	async addressClick(currentAddress, addressType) {
		let address = { ...this.state.investorAddress, type: addressType };

		this.setState({ investorAddress: address })
		if (currentAddress) {
			this.editInvestorAddress(currentAddress)
			await this.setState({ copiedAddress: { ...Shared.EmptyInvestorAddress } });
		}
		else {
			let copiedAddress = this.props.investor?.investorAddress.find(ad => ad.type !== addressType && ad.type !== 'Other');
			if (copiedAddress) {
				await this.setState({ copiedAddress: { ...copiedAddress } });
			}
			await this.setState({ investorAddress: { ...Shared.EmptyInvestorAddress, type: addressType } });
			this.addAddressDialogToggle();
		}
	}

	async handleNext() {
		await this.validate();
	}

	private async validate() {
		await ValidationService.validateInvestor('InvestorAddress')
			.then(result => {
				this.props.setCaseIsValid(result.totalIssues === 0)
				GlobalService.sendNotification(result);
			});
	}

	public render() {
		const investorType: any = this.props.investor!.type;
		return (
			<div className="stepContent">
				{this.props.bindSubmitFormHandler!(this.handleNext)}
				<h1>{investorType === "Entity" ? "Investor Addresses" : "Investor Address"}</h1>
				<div className={`stepLeft`}>
					<div className={`overflowText`}>
						{
							investorType === "Entity" ?
								<><div>
									<h3 className="stepCount">{`Step ${this.props.currentStep}`}</h3>
									<h2>Please add addresses</h2>
								</div>
									<article>Please enter a Mailing and Registered address</article>
								</> :
								<>
									<div>
										<h3 className="stepCount">{`Step ${this.props.currentStep}`}</h3>
										<h2>Please add a residential address</h2>
									</div>
									<article>
										<i>Note: The residential address cannot be a Po Box.</i>
									</article>
								</>
						}
					</div>
				</div>
				<div className={'stepRight ' + styles.addressItems}>
					<div className="rowWrap">
						{
							addressTypes.map((address, index) => {
								const currentAddress = this.findAddressType(address.addressType);
								const hasNotification = address.addressType === this.props.activeNotification?.notificationKey || ( this.props.activeNotification && this.props.activeNotification[0]?.notificationKey === "InvestorAddresses");
								const description = this.props.activeNotification?.length > 0 ? this.props.activeNotification[0]?.description : this.props.activeNotification?.description;
								return (
									this.state.investor.investorAddress !== undefined && currentAddress
										?
										<div key={index} className={`${styles.addressItem} ${styles.active} ${(address.addressType !== "Registered" && investorType === "Individual") ? styles.hideMe : ""}`}>
											<div className={`largeIcon icon-tickcircle ${styles.circle}} ${styles.activeCircle}`}></div>
											<div className={styles.addressSection}>
												<h4 className={styles.addressTitle}>{currentAddress?.type} address</h4>
												<div className={styles.addressText}>
													{currentAddress?.name ? currentAddress?.name + ',' : null} {currentAddress?.line1}, {currentAddress?.line2}, {currentAddress?.city}, {currentAddress?.state}, {currentAddress?.zip}, {currentAddress?.countryCode}
												</div>
												<button className="iconButton smallText" onClick={() => this.deleteInvestorAddress(currentAddress.id)}>CLEAR</button>
												<div className={styles.clickArea} onClick={() => this.addressClick(currentAddress, address)}></div>
											</div>
											<LocationOnOutlined className={`${styles.location} ${styles.locationActive}`} />
										</div>
										:
										<div key={index}>
											<div className={`${hasNotification ? styles.hasNotification : ""} ${styles.addressItem} ${(address.addressType !== "Registered" && investorType === "Individual") ? styles.hideMe : ""}`}>
												<div className={`largeIcon icon-circle ${styles.circle}`}></div>
												<div className={styles.addressSection}><h4 className={styles.inActive}>Add {this.getAddressText(address)} Address</h4></div>
												<div className={styles.clickArea} onClick={() => this.addressClick(currentAddress, address.addressType)}></div>
												<LocationOnOutlined className={styles.location} />

											</div>
											{hasNotification && (investorType === "Individual" && address.addressType !== "Registered" ? false: true) ? <span className={styles.notificationText}>{description}</span> : null}
										</div>
								)
							})
						}
					</div>
				</div>

				<AddAddress
					copiedAddress={this.state.copiedAddress}
					isOpen={this.state.addAddressDialogOpen}
					handleClose={this.addAddressDialogToggle}
					investorAddress={this.state.investorAddress}
					handleSubmit={this.handleSubmit}
					confirmText={"Confirm"}
					cancelText={"Cancel"}
					countries={this.props.countries}
				/>
			</div>
		)
	}
}