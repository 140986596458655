import React, { Component } from 'react';
import img from '../../assets/summaryErrorImage.svg';
import styles from './summaryIssues.module.scss';
import { SubmitCase } from "./submitCase";

export class SummaryIssues extends Component {
	render() {
		return (
			<div className={styles.layout}>
				<img src={img} alt="Summary Error"/>
				<div className={styles.intro}>
					<h2>We're almost ready to submit your documents for review</h2>
					<div className={styles.content}>
						<p>There are a few issues we have found that you may wish to attend to before submitting</p>
					</div>
					<SubmitCase />
				</div>
			</div>
		);
	}
}