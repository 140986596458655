import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form, Field } from 'formik';
import { TextFieldMaples, SelectMaples } from 'msg-react-lib';
import styles from './investorAddress.module.scss';
import * as Shared from '../shared/sharedTypes'
import { FormControlLabel, Checkbox } from '@material-ui/core';

export default function AddAddress(props) {
  const [investorAddress, setInvestorAddress] = useState(Shared.EmptyInvestorAddress);
  const [copyAddress, setCopyAddress] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [copyAddressTitle, setCopyAddressTitle] = useState('');

  const handleCheckboxChange = async (event: any) => {
    setCopyAddress(event.target.checked);
    if (event.target.checked) {
      let copiedAddress = {
        ...props.copiedAddress, id: 0, type: props.investorAddress.type
      };
      setInvestorAddress(copiedAddress);
      setDisableFields(true);
    }
    else {
      let emptyAddress = { ...Shared.EmptyInvestorAddress, type: props.investorAddress.type };
      setInvestorAddress(emptyAddress);
      setDisableFields(false);
    }
  }

  const loadData = () => {
    let title: string = props.copiedAddress && props.copiedAddress.id > 0 ? `Same as ${props.copiedAddress.type} address` : 'Same as above';
    setCopyAddressTitle(title);
    setInvestorAddress(props.investorAddress);
    setCopyAddress(false);
    setDisableFields(false);
  }

  useEffect(() => {
	  loadData()
	  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.investorAddress])

  return (
    <Dialog
      fullWidth={true}
      maxWidth='xs'
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.addressType} Address </DialogTitle>

      <Formik enableReinitialize initialValues={investorAddress} onSubmit={props.handleSubmit}>
        {
          ({ submitForm }) => (
            <Fragment>
              <DialogContent>
                <Form className={styles.formGrid}>
                  {props.copiedAddress.id > 0 ?
                    <FormControlLabel
                      style={{ marginLeft: '0' }}
                      value="copyAddress"
                      label={copyAddressTitle}
                      labelPlacement="end"
                      control={
                        <Field name="copyAddress">
                          {({
                            field
                          }) => (
                              <Checkbox color="primary" name="copyAddress" onChange={handleCheckboxChange} checked={copyAddress} />
                            )}
                        </Field>
                      } /> : null}

                  <Field                    
                    name="name"
                    component={TextFieldMaples}
                    label="Address Name"
                    disabled={disableFields}
                  />
                  <Field
                    required
                    name="line1"
                    component={TextFieldMaples}
                    label="Line1"
                    disabled={disableFields}
                  />
                  <Field
                    name="line2"
                    component={TextFieldMaples}
                    label="Line2"
                    disabled={disableFields}
                  />
                  <div className={styles.twoColumn}>
                    <Field
                      name="city"
                      component={TextFieldMaples}
                      label="City"
                      disabled={disableFields}
                    />
                    <Field
                      name="state"
                      component={TextFieldMaples}
                      label="State / Province / County"
                      disabled={disableFields}
                    />
                  </div>
                  <div className={styles.twoColumn}>
                    <Field
                      name="zip"
                      component={TextFieldMaples}
                      label="ZIP / Post Code"
                      disabled={disableFields}
                    />
                    <Field
                      required
                      name="countryCode"
                      label="Country"
                      options={props.countries!.map((country) => {
                        return (
                          { value: country.code, label: country.name }
                        )
                      })}
                      component={SelectMaples}
                      disabled={disableFields}
                    />
                  </div>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" type="submit" color="primary" onClick={submitForm} disableElevation>{props.confirmText}</Button>
                <Button onClick={props.handleClose} color="primary">{props.cancelText}</Button>
              </DialogActions>
            </Fragment>
          )
        }
      </Formik>
    </Dialog>
  );
}