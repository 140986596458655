import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/layout';
import { Home } from './components/home';
import { Celebration } from './components/shared/celebration/celebration';
import { SummaryIssues } from './components/investors/summaryIssues';
import { InvestorProfile } from './components/investors/individual/profile';
import { DataProtection } from './components/investors/dataProtection';
import { InvestorAddress } from './components/investors/investorAddress';
import { DueDiligenceConsent } from './components/investors/dueDiligenceConsent';
import { EnhancedDueDiligence } from './components/investors/enhancedDueDiligence';
import { CitizenshipStatus } from './components/investors/individual/citizenshipStatus';
import { TaxResidencies } from './components/investors/individual/taxResidencies';
import { EntityProfile } from './components/investors/entity/profile';
import { ErrorBoundary } from './components/shared/errorBoundary/errorBoundary';
import { Unauthorized } from './components/shared/misc/unauthorized';
import { NotFound } from './components/shared/misc/notFound';
import EntityChart from './components/entityChart/entityChart';
import SignIn from './components/signIn';
import CompleteSignup from './components/completeSignup';
import ForgotPasswordInitiate from './components/forgotPassword/forgotPasswordInitiate';
import ForgotPasswordVerify from './components/forgotPassword/forgotPasswordVerify';
import ForgotPasswordReset from './components/forgotPassword/forgotPasswordReset';

export default class App extends Component {
	static displayName = App.name;

	render() {
		return (
			<Layout>
				<ErrorBoundary>
					<Route exact path='/' component={Home} />
					<Route path='/investorProfile' component={InvestorProfile} />
					<Route path='/dataProtection' component={DataProtection} />
					<Route path='/investorAddress' component={InvestorAddress} />
					<Route path='/dueDiligenceConsent' component={DueDiligenceConsent} />
					<Route path='/enhancedDueDiligence' component={EnhancedDueDiligence} />
					<Route path='/citizenshipStatus' component={CitizenshipStatus} />
					<Route path='/taxResidencies' component={TaxResidencies} />
					<Route path='/entityProfile' component={EntityProfile} />
					<Route path='/finished' component={Celebration} />
					<Route path='/summaryIssues' component={SummaryIssues} />
					<Route path='/unauthorized' component={Unauthorized} />
					<Route path='/notFound' component={NotFound} />
					<Route path='/entityChart' component={EntityChart} />
					<Route path='/signIn' component={SignIn} />
					<Route path='/completeSignup' component={CompleteSignup} />
					<Route path='/forgotPasswordInitiate' component={ForgotPasswordInitiate} />
					<Route path='/forgotPasswordVerify' component={ForgotPasswordVerify} />
					<Route path='/forgotPasswordReset' component={ForgotPasswordReset} />
				</ErrorBoundary>
			</Layout>
		);
	}
}