import * as Shared from '../shared/sharedTypes';
import { UnauthorizedService } from './unauthorizedService';

export class HighRiskJurisdictionsService {
	static async getHighRiskJurisdictions(): Promise<Array<Shared.ICountry>> {
		return UnauthorizedService.authorizedFetch("/api/HighRiskJurisdictions", {
			method: "get",
			headers: {
				"Content-type": "application/json"
			}
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}
}