const redrawLines = (styles, setConnectorValues) => {
	// Find connectors
	let connectorArray = document.getElementsByClassName(styles.connectorWrap);
	// Init vars
	let positiveCount = 3;
	let negativeCount = 3;
	let prevSourceEl = '';
	let tempConnectorValues = {};

	// Iterate over connectors
	Array.from(connectorArray).forEach((connector) => {
		// Find source node and target node for this connector
		let targetEl = connector.getAttribute('data-target');
		let sourceEl = connector.getAttribute('data-source');
		let targetElDOM = targetEl ? document.getElementById(targetEl) : undefined;
		// Get the distance the chart is positioned from the top of its scrollable parent (used for positioning offsets later)
		let scrollOffset = document.getElementById('chartEl')?.scrollTop;
		if (targetEl && sourceEl && targetElDOM) {
			// Get chart position and target/source element positions
			let chartHeight = document.getElementById('chartEl')?.getBoundingClientRect().top || 0;
			let targetHeight = document.getElementById(targetEl)?.getBoundingClientRect().top || 0;
			// Adjust targetHeight to get the middle of the title rather than the top
			targetHeight = targetHeight + 20 + (scrollOffset || 0);
			let sourceHeight = document.getElementById(sourceEl)?.getBoundingClientRect().top || 0;
			// Adjust sourceHeight to get the middle of the node rather than the top
			sourceHeight = sourceHeight + 29 + (scrollOffset || 0);

			// Calculate height and position of connector line needed
			let height = targetHeight && sourceHeight ? sourceHeight - targetHeight : 0;
			let sourceElleft = document.getElementById(sourceEl)?.getBoundingClientRect().left || 0;
			let chartLeft = document.getElementById('chartEl')?.getBoundingClientRect().left || 0;

			// If this is the first time we're looking at this source node, reset the offset values
			if (prevSourceEl !== sourceEl) {
				negativeCount = 3;
				positiveCount = 3;
			}
			if (height !== undefined) {
				if (height < 0) {
					// If there's no previous line, move the line up to the appropriate starting point
					if (positiveCount <= 3 && negativeCount <= 3) {
						negativeCount = -3;
					}
					// Assign values to object key based on target and source elements for later retrieval
					tempConnectorValues[sourceEl + targetEl] = {
						height: Math.abs(Math.floor(height)) - (negativeCount + 6),
						// Negative Y scale flips the svg image so the line goes down, multiplying translation values by -1 inverts them to match
						transform: 'scale(1, -1) translate(' + (negativeCount * -1) + 'px, ' + (negativeCount * -1) + 'px)',
						top: ((sourceHeight - chartHeight) + height) + (negativeCount + 9),
						left: (sourceElleft - chartLeft) + 196,
						done: true
					}
					// Increment negative count so that if the next line also goes down, it will be spaced away from this one
					negativeCount = negativeCount + 7;
				} else {
					// Assign values to object key based on target and source elements for later retrieval
					tempConnectorValues[sourceEl + targetEl] = {
						height: Math.abs(Math.floor(height) + positiveCount),
						top: (targetHeight - chartHeight),
						left: (sourceElleft - chartLeft) + 190,
						transform: 'translate(' + positiveCount + 'px, -3px)',
						done: true
					}
					// Increment positiveCount count so that if the next line also goes up, it will be spaced away from this one
					positiveCount = positiveCount + 6;
				}
			}
			// Store this source element so that we can see if the next iteration is from the same source 
			prevSourceEl = sourceEl;
		}
	});
	// Assign all stored calculated styles to object accessible by the render function
	setConnectorValues(tempConnectorValues);
	return;
}

export default redrawLines;