import React, { Component } from 'react';
import * as Shared from '../../../shared/sharedTypes'
import { Document } from '../../../shared/documentUpload/documentComponent';
import MessageDialog from '../../../shared/dialogs/dialogMessage';
import { EntityBeneficialOwnerDocumentService } from '../../../services/entityBeneficialOwnerDocumentService';
import { InvestorService } from '../../../services/investorService';
import { ValidationService } from '../../../services/validationService';
import { GlobalService } from '../../../services/globalService';

const proofOfIdentityHighRiskTitle: string = "Proof of Identification (Certified)"
const proofOfIdentityLowRiskTitle: string = "Proof of Identification"
const proofOfAddressHighRiskTitle: string = "Proof of Address (Certified)"
const proofOfAddressLowRiskTitle: string = "Proof of Address"
const structureChartDocName: string = "Structure Chart (Optional)"
const proofOfIdentityGuide: string = "Certified clear copy of a current valid Passport, National ID Card or Driver's License (bearing photo, signature, nationality, date and place of birth"
const proofOfAddressGuide: string = "Certified copy of Bank or Legal Reference letter or Utility Bill issued within the last 3 months that confirms your residential address. Note that we cannot accept mobile telephone bills or bank/credit card statements."
const structureChartGuid: string = "Where Beneficial Ownership is held indirectly through corporate intermediaries, please upload a structure chart which demonstrates the ownership structure."
const proofOfIdentityDoc: Shared.IDocument = {
  ...Shared.EmptyDocument,
  type: 'BeneficialOwnerProofOfIdentity',
}
const proofOfAddressDoc: Shared.IDocument = {
  ...Shared.EmptyDocument,
  type: 'BeneficialOwnerProofOfAddress'
}
const structureChartDoc: Shared.IDocument = {
  ...Shared.EmptyDocument,
  title: structureChartDocName,
  type: 'BeneficialOwnerStructureChart'
}

class State {
  guideTitle: string = ""
  guideDescription: string = ""
  guideDialogOpen: boolean = false
  proofOfIdentity: Shared.IDocument = proofOfIdentityDoc
  proofOfAddress: Shared.IDocument = proofOfAddressDoc
  structureChart: Shared.IDocument = structureChartDoc
}

export class EntityBenenficialOwnerDocumentsComponent extends Component<any, State> {
  state = new State();
  constructor(props: Shared.IEntityProps) {
    super(props)
    this.loadDocuments = this.loadDocuments.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.saveDocument = this.saveDocument.bind(this);
    this.clearDocument = this.clearDocument.bind(this);
    this.setNextActiveStep = this.setNextActiveStep.bind(this);
    this.validateBeneficialOwnerDocuments = this.validateBeneficialOwnerDocuments.bind(this);
  }

  componentDidMount() {
    this.loadDocuments()
  }

  async loadDocuments() {
    let highRiskInvestor: boolean = this.props.entityInvestor?.highRiskInvestor === true;
    var document: Shared.IDocument = this.props.beneficialOwner!.documents.find((x: { type: string; }) => x.type === 'BeneficialOwnerProofOfIdentity')
    var proofOfIdentityTitle = highRiskInvestor ? proofOfIdentityHighRiskTitle : proofOfIdentityLowRiskTitle;
    if (document !== undefined) {
      document.title = proofOfIdentityTitle
      this.setState({ proofOfIdentity: document })
    } else {
      proofOfIdentityDoc.title = proofOfIdentityTitle
      this.setState({ proofOfIdentity: proofOfIdentityDoc })
    }

    document = this.props.beneficialOwner!.documents.find((x: { type: string; }) => x.type === 'BeneficialOwnerProofOfAddress')
    var addressTitle = highRiskInvestor ? proofOfAddressHighRiskTitle : proofOfAddressLowRiskTitle;
    if (document !== undefined) {
      document.title = addressTitle
      this.setState({ proofOfAddress: document })
    } else {
      proofOfAddressDoc.title = addressTitle
      this.setState({ proofOfAddress: proofOfAddressDoc })
    }

    document = this.props.beneficialOwner!.documents.find((x: { type: string; }) => x.type === 'BeneficialOwnerStructureChart')
    if (document !== undefined) {
      document.title = structureChartDocName
      this.setState({ structureChart: document })
    } else {
      this.setState({ structureChart: structureChartDoc })
    }
  }

  toggleGuideDialogClose(type: string) {
    this.setState({
      guideTitle: type === "id" ? "ID Guide" : type === "address" ? "Address Guide" : "Structure Chart Guide",
      guideDescription: type === "id" ?
        proofOfIdentityGuide : type === "address" ?
          proofOfAddressGuide : structureChartGuid,
      guideDialogOpen: !this.state.guideDialogOpen
    })
  }

  private async populateBeneficialOwnerData(beneficialOwnerId: string) {
    await InvestorService.getBeneficialOwner(beneficialOwnerId)
      .then(async result => {
        await this.props.updateBeneficalOnwer(result);
        this.loadDocuments();
      })
  };

  async uploadDocument(values: Shared.IDocument) {
    if (values.content.files[0] === undefined) {
      return
    }
    const formData = new FormData();
    formData.append('name', values.content.files[0].name);
    formData.append('type', values.type);
    formData.append('content', values.content.files[0]);
    formData.append('BeneficialOwnerId', this.props.beneficialOwner.id);

    await EntityBeneficialOwnerDocumentService.uploadDocument(formData)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack('success', response.message);
          await this.populateBeneficialOwnerData(this.props.beneficialOwner.id);
          await this.validateBeneficialOwnerDocuments();
        }
      });
  }

  async saveDocument(values: Shared.IDocument) {
    let document = {
      ...values,
      beneficialOwnerId: this.props.beneficialOwner.id
    }
    await EntityBeneficialOwnerDocumentService.saveDocument(document)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack('success', response.message);
          await this.populateBeneficialOwnerData(this.props.beneficialOwner.id);
          await this.validateBeneficialOwnerDocuments();
        }
      });
  }

  async clearDocument(values: Shared.IDocument) {
    await EntityBeneficialOwnerDocumentService.clearDocument(values.id, this.props.beneficialOwner.id)
      .then(async response => {
        if (response.errors !== undefined) {
          Object.keys(response.errors).map((key) => (
            GlobalService.sendSnack('error', `${response.errors[key]}`)
          ));
        }
        else {
          GlobalService.sendSnack('success', response.message);
          await this.populateBeneficialOwnerData(this.props.beneficialOwner.id);
          await this.validateBeneficialOwnerDocuments();
        }
      });
  }

  async setNextActiveStep() {
    await this.validateBeneficialOwnerDocuments();
    this.props.handleClose();
  }

  async validateBeneficialOwnerDocuments() {
    await ValidationService.validateEntityBeneficialOwner(this.props.beneficialOwner.id, "Documents", true)
      .then(result => {
        GlobalService.sendNotification(result)
      });
  }

  render() {
    return (
      <>
        {this.props.bindGetNextStepHandler!(this.setNextActiveStep)}
        <div className="stepContent">
          <h1>Beneficial Owner Documentation</h1>
          <div className={`stepLeft`}>
            <div className={`overflowText`}>
              <h2>Upload Documents</h2>
              <article>
                <p>In this step the Beneficial Owner can be verified by uploading a proof of Identification and proof of residential address.<br /><br />In addition, if the Beneficial Ownership is held indirectly through corporate intermediaries, please upload a structure chart which demonstrates the ownership structure</p>
              </article>
            </div>
          </div>
          <div className={`stepRight stepDetails`}>
            <div className="rowWrap">
              <Document
                document={this.state.proofOfIdentity}
                uploadDocument={this.uploadDocument}
                saveDocument={this.saveDocument}
                clearDocument={this.clearDocument}
                openGuide={() => { this.toggleGuideDialogClose("id") }} />
              <Document
                document={this.state.proofOfAddress}
                uploadDocument={this.uploadDocument}
                saveDocument={this.saveDocument}
                clearDocument={this.clearDocument}
                openGuide={() => { this.toggleGuideDialogClose("address") }} />
              <Document
                document={this.state.structureChart}
                uploadDocument={this.uploadDocument}
                saveDocument={this.saveDocument}
                clearDocument={this.clearDocument}
                openGuide={() => { this.toggleGuideDialogClose("structure") }} />
            </div>
          </div>
        </div>
        <MessageDialog
          isOpen={this.state.guideDialogOpen}
          handleClose={() => { this.setState({ guideDialogOpen: !this.state.guideDialogOpen }) }}
          title={this.state.guideTitle}
          description={this.state.guideDescription}
          cancelText="Cancel"
          confirmText="Delete"
        />
      </>
    );
  }
}