import { UnauthorizedService } from './unauthorizedService';

export class ValidationService {
  static async getValidations() {
    return await UnauthorizedService.authorizedFetch('api/Validation/', {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => UnauthorizedService.checkUnauthorized(response).json());
  }

  static async validateInvestor(step: string, isFinalStep: boolean = false) {
    let request = {
      Section: "Investor",
      StepName: step,
      IsFinalStep: isFinalStep
    }
    return await UnauthorizedService.authorizedFetch('api/Validation/', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then(response => UnauthorizedService.checkUnauthorized(response).json());
  }

  static async validateControllingPerson(id: string, step: string) {
    let request = {
      Section: "ControllingPerson",
      StepName: step,
      ControllingPersonId: id,
      IsFinalStep: true
    }
    return await UnauthorizedService.authorizedFetch('api/Validation/', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then(response => UnauthorizedService.checkUnauthorized(response).json());
  }

  static async validateSecondLevelControllingPerson(controllingPersonId: string, secondLevelControllingPersonId: string, step: string) {
    let request = {
      Section: "SecondLevelControllingPerson",
      StepName: step,
      ControllingPersonId: controllingPersonId,
      SecondLevelControllingPersonId: secondLevelControllingPersonId,
      IsFinalStep: true
    }
    return await UnauthorizedService.authorizedFetch('api/Validation/', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then(response => UnauthorizedService.checkUnauthorized(response).json());
  }

  static async validateControllingPersonBeneficialOwner(controllingPersonId: string, beneficialOwnerId: string, step: string, isFinalStep: boolean) {
    let request = {
      Section: "ControllingPersonBeneficialOwner",
      StepName: step,
      ControllingPersonId: controllingPersonId,
      BeneficialOwnerId: beneficialOwnerId,
      IsFinalStep: isFinalStep
    }
    return await UnauthorizedService.authorizedFetch('api/Validation/', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then(response => UnauthorizedService.checkUnauthorized(response).json());
  }

  static async validateEntityBeneficialOwner(id: string, step: string, isFinalStep: boolean) {
    let request = {
      Section: "BeneficialOwner",
      StepName: step,
      BeneficialOwnerId: id,
      IsFinalStep: isFinalStep
    }
    return await UnauthorizedService.authorizedFetch('api/Validation/', {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then(response => UnauthorizedService.checkUnauthorized(response).json());
  }

  static async removeValidation(step: string) {
    return await UnauthorizedService.authorizedFetch('api/Validation/' + step, {
      method: "delete",
      headers: {
        "Content-Type": "application/json"
      },
    }).then(response => UnauthorizedService.checkUnauthorized(response).json());
  }

	static async validateEntitySubmit() {
		return await UnauthorizedService.authorizedFetch('api/Validation/EntitySubmit', {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},			
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}
}