import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextFieldMaples } from 'msg-react-lib';
import { Formik, Form, Field } from "formik";
import Button from '@material-ui/core/Button';
import { AccountService } from '../services/accountService';
import * as Yup from "yup";

export default function ForgotPasswordReset(props) {
	const [showError, setShowError] = useState(false);
	const history = useHistory();
	const formInitialValues = {
		newPassword: '',
		repeatNewPassword: ''
	}

	const schema = Yup.object({
		newPassword: Yup.string()
			.required('Password is required')
			.matches(
				/^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[\$\%\&])|(?=.*[A-Z])(?=.*\d)(?=.*[\$\%\&])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\$\%\&])).{12,}$/,
				"Must Contain Minimum 12 Characters. Should have atleast 3 of the 4 types - Uppercase, Lowercase, Number, Special Case Character"
			),
		repeatNewPassword: Yup.string().required('Repeat new password is required')
			.oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
	});

	const handleVerify = async (values) => {
		let success = await AccountService.checkPasswordReset({ username: props.location.state.userId, newPassword: values.newPassword, flowId: props.location.state.id });
		if (success) {
			history.push('/');
		}
		else {
			setShowError(true);
		}
	};

	return (
		<div>
			<h3>Create a new password</h3>
			{showError ? <h3>Error occured. Please try again after 5 minutes.</h3> : null}
			<h5>Please enter a new password.</h5>
			<Formik enableReinitialize initialValues={formInitialValues} validationSchema={schema} onSubmit={handleVerify}>
				{
					({ dirty }) => (
						<Form>
							<div className="row">
								<Field name="newPassword" component={TextFieldMaples} type="password" label="New password" className="col-2" required />
							</div>
							<div className="row">
								<Field name="repeatNewPassword" component={TextFieldMaples} type="password" label="Repeat new password" className="col-2" required />
							</div>
							<div className="row">
								<Button type="submit" color="primary" variant="contained" disabled={!dirty}>Reset Password</Button>
							</div>
						</Form>
					)
				}
			</Formik>
		</div>
	);
}