import PubSub from 'pubsub-js';

export class GlobalService {

	static async sendSnack(variant, message, duration?, position?) {
		PubSub.publish('SNACKBAR', {
			variant,
			message,
			duration,
			position
		});
	}

	static async sendNotification(obj) {
		PubSub.publish('SHOW_NOTIFICATIONS', obj);
	}
}