import React, { forwardRef, useImperativeHandle } from 'react';
import Button from '@material-ui/core/Button';
import styles from './dialogStepper.module.scss';
import { InvestorProfile } from '../../investors/individual/profile';
import { InvestorAddress } from '../../investors/investorAddress';
import { DueDiligenceConsent } from '../../investors/dueDiligenceConsent';
import { DataProtection } from '../../investors/dataProtection';
import { EnhancedDueDiligence } from '../../investors/enhancedDueDiligence';
import { CitizenshipStatus } from '../../investors/individual/citizenshipStatus';
import { TaxResidencies } from '../../investors/individual/taxResidencies';
import InvestorDocuments from '../../investors/investorDocuments';
import { SummaryIssues } from '../../investors/summaryIssues';
import ArrowButton from '../animatedComponents/arrowButton/arrowButton';
import { useHistory } from "react-router-dom";
import { ValidationService } from '../../services/validationService';
import SubmitIndividual from '../dialogs/dialogSubmitIndividual'

const maxSteps = 7;

function DialogStepper(props, ref) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [unmounting, setUnmounting] = React.useState('');
  const [caseIsValid, setCaseIsValid] = React.useState(true);
  const [submitIndividualDialogCanceled, setSubmitIndividualDialogCancel] = React.useState(false);
	const [notificationClicked, setNotificationClicked] = React.useState(false);
	const [activeNotification, setActiveNotification] = React.useState(); 
  const activeButton = false;
  const history = useHistory();

  const showSubmitDialog = (props.investor.mainWorkflowCompleted ?? false) && caseIsValid && notificationClicked && !submitIndividualDialogCanceled;
  useImperativeHandle(ref, () => ({
    setStep(step) {
		  setNotificationClicked(true);
		  setActiveStep(step[0].step);
		  setActiveNotification(step);
    }
  }));

  const setFormikProps = (props) => { }

  var submitForm = () => {
	};

	var getNextStep = async () => {
		setUnmounting('next');
		if (activeStep >= maxSteps - 1) {
			await ValidationService.validateInvestor('DataProtection')
				.then(result => {
					setCaseIsValid(result.totalIssues === 0);
				})
			props.toggleNotificationsPanel(true);
			if (!caseIsValid) {
				props.toggleNotificationsPanel(false);
				setActiveStep(7);
			} else {
				submitInvestor();
			}
		} else {
			if (props.investor.usCitizenshipStatus === 'NonUSCitizenOrResident' && activeStep === 5) {
				setActiveStep(5.1);
			}
			else if (!props.investor.fatcaCrsInScope && (Math.floor(activeStep) + 1) === 5) {
				setActiveStep(6);
			} else {
				setActiveStep(prevActiveStep => Math.floor(prevActiveStep) + 1);
			}
		}
		setUnmounting('');
	}

  var steps = maxSteps - (props.investor.fatcaCrsInScope ? 0 : 1)

  function getStepContent(step) {
    switch (step) {
      case 0:
			return <DataProtection currentStep={1} activeStep={Math.floor(activeStep) + 1} steps={steps} setActiveStep={setActiveStep}
				countries={props.countries} investor={props.investor} updateInvestor={props.updateInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
				setFormikProps={setFormikProps} setCaseIsValid={setCaseIsValid} bindGetNextStepHandler={bindGetNextStepHandler}/>;
      case 1:
			return <InvestorProfile currentStep={2} activeStep={Math.floor(activeStep) + 1} steps={steps}
				countries={props.countries} investor={props.investor} updateInvestor={props.updateInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
				setCaseIsValid={setCaseIsValid} activeNotification={activeNotification} />;
      case 2:
        return <InvestorAddress currentStep={3} activeStep={Math.floor(activeStep) + 1} steps={steps}
          countries={props.countries} investor={props.investor} updateInvestor={props.updateInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
			setCaseIsValid={setCaseIsValid} activeNotification={activeNotification} />;
      case 3:
        return <EnhancedDueDiligence currentStep={4} activeStep={Math.floor(activeStep) + 1} steps={steps} 
          countries={props.countries} investor={props.investor} updateInvestor={props.updateInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
			setFormikProps={setFormikProps} setCaseIsValid={setCaseIsValid} />;
      case 4:
        return <InvestorDocuments currentStep={5} activeStep={Math.floor(activeStep) + 1} steps={steps}
			investor={props.investor} updateInvestor={props.updateInvestor} bindSubmitFormHandler={bindSubmitFormHandler} setCaseIsValid={setCaseIsValid} activeNotification={activeNotification} />;
      case 5:
        return <CitizenshipStatus currentStep={6} activeStep={Math.floor(activeStep) + 1} steps={steps} setActiveStep={setActiveStep}
          countries={props.countries} investor={props.investor} updateInvestor={props.updateInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
          setFormikProps={setFormikProps} setCaseIsValid={setCaseIsValid} />;
      case 5.1:
        return <TaxResidencies currentStep={6} activeStep={Math.floor(activeStep) + 1} steps={steps} 
          countries={props.countries} investor={props.investor} updateInvestor={props.updateInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
          setCaseIsValid={setCaseIsValid} />;
      case 6:
        return <DueDiligenceConsent currentStep={props.investor.fatcaCrsInScope ? 7 : 6} activeStep={Math.floor(activeStep) + 1} steps={steps}
          countries={props.countries} investor={props.investor} updateInvestor={props.updateInvestor} bindSubmitFormHandler={bindSubmitFormHandler}
			setFormikProps={setFormikProps} setCaseIsValid={setCaseIsValid} activeNotification={activeNotification}/>;
      case 7:
        return <SummaryIssues />;
      default:
        return ''
    }
	}

  function handleNext() {
    submitForm()
    props.bindSubmitCurrentStepHandler(() => { })
    setTimeout(async () => {
		await getNextStep();
    }, 500);
  };

  function submitInvestor() {
    props.submitInvestor();
    history.push('/finished');
  }

  function handleBack() {
    setUnmounting('prev');
    setTimeout(() => {
      if (!props.investor.fatcaCrsInScope && (Math.ceil(activeStep) - 1) === 5) {
        setActiveStep(4);
      } else {
        setActiveStep(prevActiveStep => Math.ceil(prevActiveStep) - 1);
      }
      setUnmounting('postprev');
    }, 500);
  };

  function bindSubmitFormHandler(submitFormHandler) {
    submitForm = submitFormHandler;
    props.bindSubmitCurrentStepHandler(submitForm)
  }


	function bindGetNextStepHandler(getNextStepHandler) {
		getNextStep = getNextStepHandler
	}

  const GetButton = () => {
    if (activeStep === maxSteps - 1) {
      return (
        <Button
          className={styles.nextButton}
          color="primary"
          variant="contained"
          onClick={handleNext}
          disableElevation
        >
          Finish
        </Button>
      )
    } else if (activeStep === maxSteps) {
      return (null)
    }
    else {
      return (
        <ArrowButton handleNext={handleNext} activeButton={activeButton} />
      )
    }
  }

  return (
    <>
      <div ref={ref} className={'stepwrap ' + unmounting}>
        {getStepContent(activeStep)}
        <div className={styles.stepButtons}>
          <Button color="primary" disabled={activeStep === 0} onClick={handleBack}>
            <span className={styles.backArrow}></span>
        Back
        </Button>
          <GetButton></GetButton>
        </div>
      </div>
      <SubmitIndividual
        isOpen={showSubmitDialog}
        handleClose={() => setSubmitIndividualDialogCancel(true)}
        handleConfirm={submitInvestor}
      />
    </>
  );
}

const forwardedRef = forwardRef(DialogStepper)

export default forwardedRef;