import * as Shared from '../shared/sharedTypes';
import { UnauthorizedService } from './unauthorizedService';

export class InvestorService {
	static async getInvestor(): Promise<Shared.IInvestor> {
		return UnauthorizedService.authorizedFetch("api/investor", {
			method: "get",
			headers: {
				"Content-type": "application/json"
			}
		}).then(response => UnauthorizedService.checkCaseNotFound(response).json());
	}

	static async update(investor) {
		if (investor.typeDiscriminator === 'Entity') {
			return await this.updateEntity(investor);
		} else {
			return await this.updateIndividual(investor);
		}
	}

	private static async updateIndividual(investor: Shared.IInvestor) {
		const response = await UnauthorizedService.authorizedFetch("api/investor", {
			method: 'put',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(investor)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	private static async updateEntity(investor: Shared.IEntityInvestor) {
		const response = await UnauthorizedService.authorizedFetch("api/Investor/entity", {
			method: 'put',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(investor)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async deleteInvestorAddress(investorAddressId: number) {
		return await UnauthorizedService.authorizedFetch('api/investor/DeleteAddress/' + investorAddressId, {
			method: "delete",
			headers: {
				"Content-Type": "application/json"
			}
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}

	static async addInvestorAddress(address: Shared.IInvestorAddress) {
		return await UnauthorizedService.authorizedFetch('api/Investor/AddAddress', {
			method: "post",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(address)
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}

	static async updateInvestorAddress(address: Shared.IInvestorAddress) {
		return await UnauthorizedService.authorizedFetch('api/Investor/UpdateAddress', {
			method: "put",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(address)
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}

	static async updateUsCitizenshipStatus(status: string) {
		const response = await UnauthorizedService.authorizedFetch("api/individual/UpdateUSCitizenshipStatus?status=" + status, {
			method: 'put',
			headers: {
				"Content-type": "application/json"
			}
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async addTaxDetail(taxDetail: Shared.ITaxDetail) {
		const response = await UnauthorizedService.authorizedFetch("api/individual/AddTaxDetail", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(taxDetail)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async updateTaxDetail(taxDetail: Shared.ITaxDetail) {
		const response = await UnauthorizedService.authorizedFetch("api/individual/UpdateTaxDetail", {
			method: 'put',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(taxDetail)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async deleteTaxDetail(taxDetailId: number) {
		const response = await UnauthorizedService.authorizedFetch("api/individual/DeleteTaxDetail?id=" + taxDetailId, {
			method: 'delete',
			headers: {
				"Content-type": "application/json"
			}
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async submitInvestor() {
		const response = await UnauthorizedService.authorizedFetch("api/investor/Submit", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async updateBoDeclaration(status: boolean) {
		let request = { declareHavingUbo: status }
		const response = await UnauthorizedService.authorizedFetch("api/entity/UpdateBODeclaration", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(request)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async saveBeneficialOwner(beneficialOwner: Shared.IBeneficialOwner) {
		if (beneficialOwner.id !== '') {
			return await this.updateEntityBeneficialOwner(beneficialOwner);
		}
		else {
			return await this.addEntityBeneficialOwner(beneficialOwner);
		}
	}

	private static async addEntityBeneficialOwner(controllingPErsonBeneficialOwner: Shared.IBeneficialOwner) {
		const response = await UnauthorizedService.authorizedFetch("api/entity/beneficialOwner", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(controllingPErsonBeneficialOwner)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	private static async updateEntityBeneficialOwner(controllingPErsonBeneficialOwner: Shared.IBeneficialOwner) {
		const response = await UnauthorizedService.authorizedFetch("api/entity/updateBeneficialOwer", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(controllingPErsonBeneficialOwner)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async getBeneficialOwner(beneficialOwnerId: string): Promise<Shared.IBeneficialOwner> {
		return UnauthorizedService.authorizedFetch("api/entity/beneficialOwner?beneficialOwnerId=" + beneficialOwnerId, {
			method: "get",
			headers: {
				"Content-type": "application/json"
			}
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}

	static async updateMainWorkflowStatus(status: boolean) {
		let request = { mainWorkflowCompleted: status }
		const response = await UnauthorizedService.authorizedFetch("api/investor/UpdateMainWorkflowStatus", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(request)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async deleteBeneficialOwner(id: string) {
		const response = await UnauthorizedService.authorizedFetch("/api/entity/deleteBeneficialOwner?beneficialOwnerId=" + id, {
			method: 'delete',
			headers: {
				"Content-type": "application/json"
			}
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}
}