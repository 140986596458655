import React, { Component } from 'react';
import * as Shared from '../../../../shared/sharedTypes'
import { Document } from '../../../../shared/documentUpload/documentComponent';
import { GlobalService } from '../../../../services/globalService';
import { ControllingPersonService } from '../../../../services/controllingPersonService';
import { ControllingPersonDocumentService } from '../../../../services/controllingPersonDocumentService';
import { ValidationService } from '../../../../services/validationService';
import MessageDialog from '../../../../shared/dialogs/dialogMessage';
import styles from "../../../../../assets/styles/notifications.module.scss";

const proofOfIdentityGuide: string = "Clear copy of a current valid Passport, National ID Card or Driver's License (bearing photo, signature, nationality, date and place of birth"
const proofOfAddressGuide: string = "Copy of Bank or Legal Reference letter or Utility Bill issued within the last 3 months that confirms your residential address. Note that we cannot accept mobile telephone bills or bank/credit card statements."
const proofOfIdentityHighRiskTitle: string = "Proof of Identification (Certified)"
const proofOfIdentityLowRiskTitle: string = "Proof of Identification"
const proofOfAddressHighRiskTitle: string = "Proof of Address (Certified)"
const proofOfAddressLowRiskTitle: string = "Proof of Address"

class State {
	guideTitle: string = ""
	guideDescription: string = ""
	guideDialogOpen: boolean = false
	proofOfAddress: Shared.IDocument = {
		...Shared.EmptyDocument,
		title: 'Proof of Address',
		type: 'ProofOfAddress'
	}
	proofOfIdentity: Shared.IDocument = {
		...Shared.EmptyDocument,
		title: 'Proof of Identification',
		type: 'ProofOfIdentity'
	}
}
export class IndividualControllingPersondDocuments extends Component<Shared.IEntityProps> {
	state = new State();
	constructor(props: Shared.IEntityProps) {
		super(props)
		this.loadDocuments = this.loadDocuments.bind(this);
		this.toggleGuideDialogClose = this.toggleGuideDialogClose.bind(this);
		this.setNextActiveStep = this.setNextActiveStep.bind(this);
		this.uploadDocument = this.uploadDocument.bind(this);
		this.saveDocument = this.saveDocument.bind(this);
		this.clearDocument = this.clearDocument.bind(this);
		this.validateControllingPersonDocuments = this.validateControllingPersonDocuments.bind(this);
	}

	componentDidMount() {
		if (this.props.controllingPerson?.documents !== undefined) {
			this.loadDocuments()
		}
	}

	private async loadDocuments() {
		let highRiskInvestor: boolean = this.props.entityInvestor?.highRiskInvestor === true;
		var proofOfIdentityTitle = highRiskInvestor ? proofOfIdentityHighRiskTitle : proofOfIdentityLowRiskTitle;

		var document: Shared.IDocument | undefined = this.props.controllingPerson!.documents.find(x => x.type === 'ProofOfIdentity');
		if (document !== undefined) {
			document.title = proofOfIdentityTitle
			document.controllingPersonId = this.props.controllingPerson!.id
			this.setState({ proofOfIdentity: document })
		} else {
			this.setState({
				proofOfIdentity: {
					...Shared.EmptyDocument,
					title: proofOfIdentityTitle,
					type: 'ProofOfIdentity',
					controllingPersonId: this.props.controllingPerson!.id
				}
			})
		}

		document = this.props.controllingPerson!.documents.find(x => x.type === 'ProofOfAddress')
		var addressTitle = highRiskInvestor ? proofOfAddressHighRiskTitle : proofOfAddressLowRiskTitle;
		if (document !== undefined) {
			document.title = addressTitle
			document.controllingPersonId = this.props.controllingPerson!.id
			this.setState({ proofOfAddress: document })
		} else {
			this.setState({
				proofOfAddress: {
					...Shared.EmptyDocument,
					title: addressTitle,
					type: 'ProofOfAddress',
					controllingPersonId: this.props.controllingPerson!.id
				}
			})
		}
	}

	async saveDocument(values: Shared.IDocument) {
		await ControllingPersonDocumentService.saveDocument(values)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.populateControllingPersonData(values.controllingPersonId);
					await this.validateControllingPersonDocuments();
				}
			});
	}

	async uploadDocument(values: Shared.IDocument) {
		if (values.content.files[0] === undefined) {
			return
		}
		const formData = new FormData();
		formData.append('name', values.content.files[0].name);
		formData.append('type', values.type);
		formData.append('content', values.content.files[0]);
		formData.append('ControllingPersonId', values.controllingPersonId);
		await ControllingPersonDocumentService.uploadDocument(formData)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.populateControllingPersonData(values.controllingPersonId);
					await this.validateControllingPersonDocuments();
				}
			});
	}

	async clearDocument(values: Shared.IDocument) {
		await ControllingPersonDocumentService.clearDocument(values.id, values.controllingPersonId)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.populateControllingPersonData(values.controllingPersonId);
					await this.validateControllingPersonDocuments();
				}
			});
	}

	async populateControllingPersonData(id: string) {
		await ControllingPersonService.getControllingPerson(id)
			.then(async result => {
				await this.props.updateControllingPerson!(result);
				await this.loadDocuments();
			})
	};

	toggleGuideDialogClose(type: string) {
		this.setState({
			guideTitle: type === "id" ? "ID Guide" : "Address Guide",
			guideDescription: type === "id" ?
				proofOfIdentityGuide :
				proofOfAddressGuide,
			guideDialogOpen: !this.state.guideDialogOpen
		})
	}

	async setNextActiveStep() {
		await this.validateControllingPersonDocuments();
		this.props.handleClose!();
	}

	async validateControllingPersonDocuments() {
		await ValidationService.validateControllingPerson(this.props.controllingPerson!.id, "Documents")
			.then(result => {
				GlobalService.sendNotification(result)
			});
	}

	render() {
		return (
			<>
				{this.props.bindGetNextStepHandler!(this.setNextActiveStep)}
				<div className="stepContent">
					<h1>Identification for {ControllingPersonService.getControllingPersonType(this.props.controllingPerson!.type)}</h1>
					<div className={`stepLeft`}>
						<div className={`overflowText`}>
							<h2>Upload Documents</h2>
							<article>
								<p>Maples Compliance Services (Cayman) Limited have been engaged to identify investors in line with the Cayman Islands Know Your Customer (“KYC”) standards.<br /><br /> Please provide proof of Identification and Proof of address in the fields provided.</p>
							</article>
						</div>
					</div>
					<div className={`stepRight stepDetails`}>
						<div className="rowWrap">
							<Document
								document={this.state.proofOfIdentity}
								uploadDocument={this.uploadDocument}
								saveDocument={this.saveDocument}
								clearDocument={this.clearDocument}
								openGuide={() => { this.toggleGuideDialogClose("id") }}
								classes={`${(this.props.activeNotification?.notificationKey === this.state.proofOfIdentity.type && this.state.proofOfIdentity.name === "") ? styles.hasNotification : null}`}
							/>
							<Document
								document={this.state.proofOfAddress}
								uploadDocument={this.uploadDocument}
								saveDocument={this.saveDocument}
								clearDocument={this.clearDocument}
								openGuide={() => { this.toggleGuideDialogClose("address") }}
								classes={`${(this.props.activeNotification?.notificationKey === this.state.proofOfAddress.type && this.state.proofOfAddress.name === "") ? styles.hasNotification : null}`}
							/>
						</div>
					</div>
				</div>
				<MessageDialog
					isOpen={this.state.guideDialogOpen}
					handleClose={() => { this.setState({ guideDialogOpen: !this.state.guideDialogOpen }) }}
					title={this.state.guideTitle}
					description={this.state.guideDescription}
					cancelText="Cancel"
					confirmText="Delete"
				/>
			</>
		);
	}
}