import { createBrowserHistory } from 'history';

export class UnauthorizedService {
	static checkUnauthorized(response) {
		const browserHistory = createBrowserHistory({ forceRefresh: true });
		if (response.status === 403) {
			browserHistory.push('/unauthorized');
		}
		else if (response.status === 401) {
			browserHistory.push('/signin');
		}
		return response;
	}

	static checkCaseNotFound(response) {
		const browserHistory = createBrowserHistory({ forceRefresh: true });
		if (response.status === 404) {
			browserHistory.push('/notFound');
		} else {
			UnauthorizedService.checkUnauthorized(response);
		}
		return response;
	}

	static authorizedFetch(url, options) {
		return fetch(url, addAuthorizationHeader(options));
	}
}

function addAuthorizationHeader(fetchOptions) {
	const newOptions = { ...fetchOptions };
	newOptions.headers = {
		...newOptions.headers,
		"Authorization": "Bearer " + localStorage.getItem("jwtToken"),
	};
	return newOptions;
}
