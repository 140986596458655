import React, { useState, useEffect, createRef } from 'react';
import * as Shared from '../shared/sharedTypes'
import { Document } from '../shared/documentUpload/documentComponent';
import { GlobalService } from '../services/globalService';
import { DocumentService } from '../services/documentService';
import { InvestorService } from '../services/investorService';
import { ValidationService } from '../services/validationService';
import MessageDialog from '../shared/dialogs/dialogMessage';
import ToolTipDocUpload from "../shared/toolTips/toolTip_docUpload";
import styles from "../../assets/styles/notifications.module.scss";

export default function InvestorDocuments(props) {
	const guideTitle = ""
	const guideDescription = ""
	const [guideDialogOpen, setGuideDialogOpen] = useState(false)
	const [requiredDocuments, setRequiredDocuments] = useState([Shared.EmptyDocument])
	const [isTourOpen, setIsTourOpen] = useState(window.localStorage.getItem('docUploadTT') !== 'true');
	const [elementsRef, setElementsRef] = useState<Array<React.RefObject<HTMLDivElement>>>([React.createRef<HTMLDivElement>()]);
	const [hasNotification, setHasNotification] = useState<any>(props.activeNotification);

	const closeTour = () => {
		setIsTourOpen(false)
		window.localStorage.setItem("docUploadTT", "true");
	}

	const loadDocuments = () => {
		let docs: Array<Shared.IDocument> = [];
		let doc: Shared.IDocument = Shared.EmptyDocument;
		let documents = props.investor!.documents;
		let highRiskInvestor: boolean = props.investor?.highRiskInvestor === true;
		if (props.investor?.requiredDocuments !== undefined) {
			props.investor?.requiredDocuments.forEach(function (item) {
				doc = {} as Shared.IDocument;
				let document = documents.find(x => x.type === item.type);
				if (document !== undefined) {
					doc.content = document.content;
					doc.id = document.id;
					doc.name = document.name;
					doc.notes = document.notes;
					doc.type = document.type;
					doc.title = highRiskInvestor ? item.highRiskTitle : item.lowRiskTitle;
					doc.guide = highRiskInvestor ? item.highRiskGuide : item.lowRiskGuide;
				} else {
					doc.id = 0;
					doc.name = '';
					doc.notes = '';
					doc.content = null;
					doc.title = highRiskInvestor ? item.highRiskTitle : item.lowRiskTitle;
					doc.type = item.type;
					doc.guide = highRiskInvestor ? item.highRiskGuide : item.lowRiskGuide;
				}
				docs.push(doc);
			})
		};

		setRequiredDocuments(docs);

	}

	useEffect(() => {
		if (requiredDocuments[0] !== Shared.EmptyDocument) {
			setElementsRef(requiredDocuments.map(() => createRef()));
		}
	}, [requiredDocuments])

	useEffect(() => {
		if (elementsRef[1]?.current) {
			const matchingIndex = requiredDocuments.findIndex(x => x.type === props.activeNotification?.notificationKey);
			if (elementsRef[matchingIndex]?.current) {
				elementsRef[matchingIndex]?.current?.scrollIntoView();
			}
		}
		setHasNotification(props.activeNotification);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [elementsRef, props.activeNotification?.notificationKey])

	const saveDocument = async (values: Shared.IDocument) => {
		await DocumentService.saveDocument(values)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await validateInvestorDocuments();
					await populateInvestorData();
				}
			});
	}

	const uploadDocument = async (values: Shared.IDocument) => {
		if (values.content.files[0] === undefined) {
			return
		}
		const formData = new FormData();
		formData.append('name', values.content.files[0].name);
		formData.append('type', values.type);
		formData.append('content', values.content.files[0]);
		await DocumentService.uploadDocument(formData)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await validateInvestorDocuments();
					await populateInvestorData();
				}
			});
	}

	const clearDocument = async (values: Shared.IDocument) => {
		await DocumentService.clearDocument(values.id)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await validateInvestorDocuments();
					await populateInvestorData();
				}
			});
	}

	const populateInvestorData = async () => {
		await InvestorService.getInvestor()
			.then(async result => {
				await props.updateInvestor(result);
			})
	};

	const handleNext = async () => {
		await validateInvestorDocuments();
	}

	const validateInvestorDocuments = async () => {
		await ValidationService.validateInvestor('Documents', props.finalStep)
			.then(result => {
				props.setCaseIsValid(result.totalIssues === 0)
				GlobalService.sendNotification(result)
			})
	}

	useEffect(() => {
		loadDocuments()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.investor.documents])

	return (
		<>
			{props.bindSubmitFormHandler!(handleNext)}
			<div className="stepContent">
				<h1>Identification Documents</h1>
				<div className={`stepLeft`}>
					<div className={`overflowText`}>
						<div>
							<h3 className="stepCount">{`Step ${props.currentStep}`}</h3>
							<h2>Upload Documents</h2>
						</div>
						<article>
							<p>Maples Compliance Services (Cayman) Limited have been engaged to identify investors in line with the Cayman Islands Know Your Customer (“KYC”) standards.<br /><br /> Please provide all documents that appear to the right of the page.</p>
						</article>
					</div>
				</div>
				<div className={`stepRight stepDetails`}>
					<div className="rowWrap">
						{requiredDocuments.map((requiredDocument, index) => {
							const checkNotifications = hasNotification?.length > 0 ? hasNotification?.find(x => x.notificationKey === requiredDocument.type) : false;
								return (
									<Document
										id={"docUpload" + index}
										key={`${requiredDocument.title}`}
										document={requiredDocument}
										uploadDocument={uploadDocument}
										saveDocument={saveDocument}
										clearDocument={clearDocument}
										refs={elementsRef[index]}
										classes={`${( (props.activeNotification?.notificationKey === requiredDocument.type && requiredDocument.name === "") || (checkNotifications && checkNotifications !== undefined) ) ? styles.hasNotification : null}`}
									/>
								)
						})}
					</div>
				</div>
			</div>
			<MessageDialog
				isOpen={guideDialogOpen}
				handleClose={() => { setGuideDialogOpen(!guideDialogOpen) }}
				title={guideTitle}
				description={guideDescription}
				cancelText="Cancel"
				confirmText="Delete"
			/>
			<ToolTipDocUpload selector="#docUpload0" isOpen={isTourOpen} closeTour={closeTour} />
		</>
	);
}