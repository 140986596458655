import React from 'react';
import styles from './entityChart.module.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Node from './entityChartNode';

export default function BeneficialOwnersSection(props) {

	let {
		dialogOpen,
		setUboGuideDialogOpen,
		entityBoDialogOpen,
		addEntityBo,
		addEntityBOStatus,
		animationInTime,
		animationOutTime,
		onAnimationFinished,
		editBeneficialOwner,
		confirmDeleteBeneficialOwner,
		nodeClicked,
		isActive,
		activeControllingPerson,
		activeNotification,
		setActiveNotification,
		investor
	} = props;

	return (
		<>
			<div className={`${styles.section} ${styles.bottomSection} ${styles.uboSection}`} id="uboEl">
				<div className={styles.titleSection}>
					<h3>Beneficial Owner(s)</h3>
					<div className={styles.buttonBar}>
						<button className="iconButton" onClick={() => setUboGuideDialogOpen(true)}>?</button>
						{
							investor.declareHavingUbo ?
								<button className="iconButton" onClick={addEntityBo}>+</button>
								: null
						}
					</div>
				</div>
				<h4><span>{investor.beneficialOwners?.length || 0}</span> added</h4>
				<p className={styles.sectiondescription}>All owners who own 10% or more required</p>
				<div className={styles.nodes}>
					{
						!investor.declareHavingUbo ?
							(
								investor.declareHavingUbo === null ?
									<Node text="Beneficial Owner Declaration" declaration={true} empty={true} click={addEntityBOStatus}></Node>
									:
									<Node text="Beneficial Owner Declaration" click={addEntityBOStatus} section="ubo" status="Completed" details={{ name: "Confirmed no BO's" }} />
							)
							:
							<>
								{
									investor.beneficialOwners.length === 0 ?
										<button className={`${styles.buttonText} declarationButton`} onClick={addEntityBOStatus}> Change Beneficial Owner Declaration</button>
										: null
								}
								<TransitionGroup component={null}>
									{
										investor.beneficialOwners.map((bo) => (

											<CSSTransition
												key={bo.id}
												timeout={{ enter: animationInTime, exit: animationOutTime }}
												classNames={'cpNode'}
												onExited={onAnimationFinished}
											>
												<Node
													editClick={editBeneficialOwner}
													deleteClick={confirmDeleteBeneficialOwner}
													click={nodeClicked}
													isActive={isActive(bo.id)}
													details={bo}
													section="ubo"
													status={bo.status}
													dialogOpen={dialogOpen}
												/>
											</CSSTransition>

										))
									}
								</TransitionGroup>

								<CSSTransition
									in={investor.beneficialOwners.length === 0}
									timeout={{ enter: animationInTime, exit: animationOutTime }}
									classNames={'cpNode'}
									unmountOnExit={true}
									onExited={onAnimationFinished}
								>
									<Node key={`uboEmpty`} empty={true} isActive={entityBoDialogOpen && !activeControllingPerson.id ? true : false} click={addEntityBo} section="ubo" activeNotification={activeNotification} setActiveNotification={setActiveNotification}></Node>
								</CSSTransition>
							</>
					}

				</div>
			</div>
		</>
	)
}