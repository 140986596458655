import React from 'react';
import { Formik, Form } from 'formik';
import * as Shared from '../sharedTypes';

export default function UploadDocument(props) {

  const fileInput = React.useRef<HTMLInputElement>(null);

  const handleSubmit = async (values: Shared.IDocument) => {
    if (values.content.files[0] === undefined) {
      return
    }
    await props.uploadDocument(values)
	props.uploadDone()
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  }

  function onFileChange(setFieldValue, submitForm) {
    return async (event) => {
      props.setLoading();
      setFieldValue("content", event.currentTarget);
      await submitForm();
    };
  }

  return (
    <Formik enableReinitialize initialValues={props.document} onSubmit={handleSubmit}>
      {
        ({ setFieldValue, submitForm }) => (
          <Form>
            <input id="content" ref={fileInput} style={{ opacity: '0', position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', zIndex: 1 }}
              name="content" type="file" accept=".doc,.docx,.pdf,.jpg,.jpeg,.png,.txt" onChange={onFileChange(setFieldValue, submitForm)} className="form-control" />
          </Form>
        )
      }
    </Formik>
  );
}