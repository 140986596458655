import * as Shared from '../shared/sharedTypes';
import { UnauthorizedService } from './unauthorizedService';

export class DocumentService {
  static async uploadDocument(document: FormData) {
		return await UnauthorizedService.authorizedFetch('api/document/upload', {
			method: "post",
			body: document
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
  }
  
  static async saveDocument(document: Shared.IDocument) {
    if (document.id !== 0) {
      return await DocumentService.updateDocument(document);
    } else {
      return await DocumentService.addDocument(document);
    }
  }

  private static async updateDocument(document: Shared.IDocument) {
    const response = await UnauthorizedService.authorizedFetch("api/document", {
      method: 'put',
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(document)
    });
    return UnauthorizedService.checkUnauthorized(response).json();
  }

  private static async addDocument(document: Shared.IDocument) {    
    const response = await UnauthorizedService.authorizedFetch("api/document", {
      method: 'post',
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(document)
    });
    return UnauthorizedService.checkUnauthorized(response).json();
  }

  static async clearDocument(documentId: number) {
    const response = await UnauthorizedService.authorizedFetch("api/document/" + documentId, {
      method: 'delete',
      headers: {
        "Content-type": "application/json"
      }
    });
    return UnauthorizedService.checkUnauthorized(response).json();
  }
}