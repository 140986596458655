import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { maplesprimary, maplesaccent } from '../src/assets/theme/maples-material-ui-theme-base-colors';
import 'msg-react-lib/dist/index.css';
import './index.scss';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string | undefined;
const rootElement = document.getElementById('root');
const theme = createTheme({
	palette: {
		primary: {
			main: maplesprimary[500]
		},
		secondary: {
			main: maplesaccent[500]
		},
	}
});

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<BrowserRouter basename={baseUrl}>
			<App />
		</BrowserRouter>
	</ThemeProvider>,
  rootElement);

// registerServiceWorker();