import * as Shared from '../shared/sharedTypes';
import { UnauthorizedService } from './unauthorizedService';

export class ControllingPersonService {
	static async getControllingPerson(controllingPersonId: string): Promise<Shared.IControllingPerson> {
		return UnauthorizedService.authorizedFetch("api/controllingPerson?controllingpersonid=" + controllingPersonId, {
			method: "get",
			headers: {
				"Content-type": "application/json"
			}
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}

  static async save(controllingPerson: Shared.IControllingPerson) {
    if (controllingPerson.id !== '') {
      return await this.updateControllingPerson(controllingPerson);
    } else {
      return await this.addControllingPerson(controllingPerson);
    }
  }

	private static async addControllingPerson(controllingPerson: Shared.IControllingPerson) {
		const response = await UnauthorizedService.authorizedFetch("api/ControllingPerson", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(controllingPerson)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
  }
  
  private static async updateControllingPerson(controllingPerson: Shared.IControllingPerson) {
		const response = await UnauthorizedService.authorizedFetch("api/ControllingPerson/updatename", {
			method: 'put',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(controllingPerson)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async updateRegulatoryStauts(controllingPerson: Shared.IControllingPerson) {
		const response = await UnauthorizedService.authorizedFetch("api/ControllingPerson/updateregulatorystauts", {
			method: 'put',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(controllingPerson)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async updateBoDeclaration(controllingPersonId: string, status: boolean) {
		let request = { Id: controllingPersonId, declareHavingUbo: status }
		const response = await UnauthorizedService.authorizedFetch("api/ControllingPerson/UpdateBODeclaration", {
			method: 'put',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(request)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async deleteControllingPerson(controllingPersonId: string) {
		const response = await UnauthorizedService.authorizedFetch("api/controllingperson/" + controllingPersonId, {
			method: 'delete',
			headers: {
				"Content-type": "application/json"
			}
		});
    return UnauthorizedService.checkUnauthorized(response).json();
  }

  static getControllingPersonType(investorType: string) {
		switch (investorType) {
			case "Company":
				return "Director";
			case "LLC":
				return "Managing Member";
			case "Partnership":
        return "General Partner";
			case "Trust":
				return "Trustee & Settlor";
			default:
				return "Controlling Person";
		}
  }
  
  static getControllingPersonsListTitle(investorType: string) {
		switch (investorType) {
			case "Company":
				return "Directors";
			case "Partnership":
				return "General Partners";
			case "LLC":
				return "Managing Members";
			case "Trust":
				return "Trustees & Settlors";
			default:
				return "Controlling Persons";
		}
	}
}