import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Node from './entityChartNode';
import Connector from './entityChartConnector';
import ControllingPersonDialogStepper from '../shared/dialogSteppers/controllingPersonDialogStepper';
import ControllingPersonsBeneficialOwnerDialogStepper from '../shared/dialogSteppers/controllingPersonBeneficialOwnerDialogStepper';
import ControllingPersonsBOStatusDialogStepper from '../shared/dialogSteppers/controllingPersonBOStatusDialogStepper';
import EntityBOStatusDialogStepper from '../shared/dialogSteppers/entityBOStatusDialogStepper';
import EntityBoDialogStepper from '../shared/dialogSteppers/entityBoDialogStepper';
import SecondLevelControllingPersonDialogStepper from '../shared/dialogSteppers/secondLevelControllingPersonDialogStepper';
import EntityDialogStepper from '../shared/dialogSteppers/entityDialogStepper';
import { ControllingPersonService } from '../services/controllingPersonService';
import { InvestorService } from '../services/investorService';
import { ConfirmDialog } from 'msg-react-lib';
import styles from './entityChart.module.scss';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import * as Shared from '../shared/sharedTypes';
import MessageGuide from '../shared/dialogs/dialogGuide';
import SubmitChart from '../shared/dialogs/dialogSubmitChart';
import { SecondLevelControllingPersonService } from '../services/secondLevelControllingPersonService';
import { ControllingPersonBeneficialOwnerService } from '../services/controllingPersonBeneficialOwnerService';
import { ValidationService } from '../services/validationService'
import { level1TourSteps, level2TourSteps } from "./toolTipSteps";
import Tour from 'reactour';
import { GlobalService } from '../services/globalService';
import redrawLines from './redrawLines';
import ControllingPersonsSection from './controllingPersonsSection';
import BeneficialOwnersSection from './beneficialOwnersSection';
import SecondLevelControllingPersonsSection from './secondLevelControllingPersonsSection';
import SecondLevelBeneficialOwnerSection from './secondLevelBeneficialOwnerSection';

function EntityChart(props, ref) {
	const [dialogOpen, toggleDialogOpen] = useState(false);
	const [secondLevelCPDialogOpen, toggleSecondLevelCPDialogOpen] = useState(false);
	const [controllingPersonBeneficialOwnerDialogOpen, toggleControllingPersonBeneficialOwnerDialogOpen] = useState(false);
	const [controllingPersonBeneficialOwnerStatusDialogOpen, toggleControllingPersonBeneficialOwnerStatusDialogOpen] = useState(false);
	const [entityBOStatusDialogOpen, toggleEntityBOStatusDialogOpen] = useState(false);
	const [entityBoDialogOpen, toggleEntityBoDialogOpen] = useState(false);
	const [entityDialogOpen, toggleEntityDialogOpen] = useState(false);
	const [confirmDialogOpen, confirmDialogToggle] = useState(false);
	const [confirmSecondLevelCPDialogOpen, confirmSecondLevelCPDialogToggle] = useState(false);
	const [confirmCPBeneficialOwnerDialogOpen, confirmCPBeneficialOwnerDialogToggle] = useState(false);
	const [confirmBeneficialOwnerDialogOpen, confirmBeneficialOwnerDialogToggle] = useState(false);
	const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false);
	const [focusColumn, setFocusColumn] = useState('');
	const [directorsGuideDialogOpen, setDirectorsGuideDialogOpen] = useState(false);
	const [uboGuideDialogOpen, setUboGuideDialogOpen] = useState(false);
	const [secondLevelDirectorsGuideDialogOpen, setSecondLevelDirectorsGuideDialogOpen] = useState(false);
	const [activeControllingPerson, setActiveControllingPerson] = useState({
		id: undefined,
		declareHavingUbo: null,
		name: "",
		type: "",
		residentialAddress: null,
		regulatedEntity: false,
		secondLevelControllingPersons: [
			{ name: '', id: undefined, status: '' }
		],
		beneficialOwners: [
			{ name: '', id: undefined, status: '' }
		],
	});
	const [activeChild, setActiveChild] = useState({
		id: undefined,
		name: "",
		type: "",
	});
	const [activeComponent, setActiveComponent] = useState({
		id: '',
		parentId: ''
	});
	const [connectorValues, setConnectorValues] = useState({});
	const [col1AnimatedIn, setCol1AnimatedIn] = useState(false);
	const [col2AnimatedIn, setCol2AnimatedIn] = useState(false);
	const [showConnectors, setShowConnectors] = useState(true);
	const [showL2Connectors, setShowL2Connectors] = useState(true);
	let animatedInTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
	const [isLevel1TourOpen, setLevel1TourOpen] = useState(false);
	const [isLevel2TourOpen, setLevel2TourOpen] = useState(false);
	const [openTour2OnDialogClose, setOpenTour2OnDialogClose] = useState(false);
	const [lastOpenDialog, setLastOpenDialog] = useState("");
	const [activeNotification, setActiveNotification] = useState();
	const animationInTime = 2000;
	const animationOutTime = 1000;

	const closeTour = (tourID) => {
		setLevel1TourOpen(false);
		setLevel2TourOpen(false);
		window.localStorage.setItem(tourID, 'true');
	}


	useEffect(() => {
		const screenResize = () => {
			let chartElement = document.getElementById('chartEl');
			if (!chartElement) return;
			chartElement.scrollTop = 0;
			redrawLines(styles, setConnectorValues)
		}
		if (props.showMainWorkflow) {
			toggleEntityDialogOpen(true);
		} else if (window.localStorage.getItem('tour1') !== 'true') {
			setTimeout(() => {
				setLevel1TourOpen(true)
			}, 2000);
		}
		window.addEventListener('resize', screenResize);
		return () => window.removeEventListener('resize', screenResize);
	}, [props.showMainWorkflow]);

	const finishMainWorkflow = () => {
		props.investor.mainWorkflowCompleted = true;
		InvestorService.updateMainWorkflowStatus(true);
		handleEntityDialogClose();
	}

	const nodeClicked = (cp) => {
		if (cp.type !== "Individual") {
			setActiveControllingPerson(cp)
		} else {
			setActiveControllingPerson({
				...cp,
				secondLevelControllingPersons: [],
				beneficialOwners: []
			})
		}
		setActiveChild({
			id: undefined,
			name: "",
			type: "",
		})
	}

	const controllingPersonDialogStepperRef = useRef<any>(null);
	const controllingPersonBeneficialOwnerDialogStepperRef = useRef<any>(null);
	const secondLevelControllingPersonDialogStepperRef = useRef<any>(null);
	const entityDialogStepperRef = useRef<any>(null);
	const entityBeneficialOwnerDialogStepperRef = useRef<any>(null);
	const history = useHistory();

	useImperativeHandle(ref, () => ({
		setStep(step) {
			setActiveNotification(step);
			deSelectChild();
			clearDirector();
			openNotificationStep(step);
		}
	}));

	async function openNotificationStep(step) {
		switch (step.section) {
			case "ControllingPerson":
				await openControllingPerson(step);
				return;
			case "SecondLevelControllingPerson":
				await openSecondLevelControllingPerson(step);
				return;
			case "ControllingPersonBeneficialOwner":
				await openControllingPersonBeneficialOwner(step);
				return;
			case "BeneficialOwner":
				await openEntityBeneficialOwner(step);
				return;
			case "Investor":
				openEntity(step);
				return;
			default:
				return;
		}
	}

	function closeLastNotificationStep() {
		switch (lastOpenDialog) {
			case "ControllingPerson":
				toggleDialogOpen(false);
				break;
			case "SecondLevelControllingPerson":
				toggleSecondLevelCPDialogOpen(false);
				break;
			case "ControllingPersonBeneficialOwner":
				toggleEntityBoDialogOpen(false);
				break;
			case "BeneficialOwner":
				toggleControllingPersonBeneficialOwnerDialogOpen(false);
				break;
			case "EntityChart":
				toggleEntityDialogOpen(false);
				break;
			default:
				setFocusColumn('');
				return;
		}
	}

	function openEntity(step: any) {
		closeLastNotificationStep();
		entityDialogStepperRef.current!.setStep(step);
		toggleEntityDialogOpen(true);
		nodeClicked({ name: props.investor.name })
		setLastOpenDialog("EntityChart");
	}

	async function openControllingPerson(step) {
		closeLastNotificationStep();
		let controllingPerson: Shared.IControllingPerson = props.investor.controllingPersons.find(cp => cp.id === step.id);
		await props.updateControllingPerson(controllingPerson);
		controllingPersonDialogStepperRef.current!.setStep(step);
		toggleDialogOpen(true);
		nodeClicked(controllingPerson);
		setFocusColumn('focusColumn2');
		setLastOpenDialog("ControllingPerson");
	}

	async function openSecondLevelControllingPerson(step) {
		closeLastNotificationStep();
		let secondLevelCP: Shared.ISecondLevelControllingPerson | undefined = Shared.EmptySecondLevelControllingPerson;
		let controllingPerson: Shared.IControllingPerson | undefined = Shared.EmptyControllingPerson;
		props.investor.controllingPersons.forEach((cp) => {
			let secondLevelControllingPerson = cp.secondLevelControllingPersons.find(i => i.id === step.id);
			if (secondLevelControllingPerson !== undefined) {
				secondLevelCP = secondLevelControllingPerson;
				controllingPerson = cp;
			}
		});
		await props.updateControllingPerson(controllingPerson);
		await props.updateSecondLevelControllingPerson(secondLevelCP);
		secondLevelControllingPersonDialogStepperRef.current!.setStep(step);
		toggleSecondLevelCPDialogOpen(true);
		nodeClicked(controllingPerson)
		subNodeClicked(secondLevelCP)
		setFocusColumn('focusColumn3');
		setLastOpenDialog("SecondLevelControllingPerson");
	}

	async function openControllingPersonBeneficialOwner(step) {
		closeLastNotificationStep();
		let beneficialOwner: Shared.IBeneficialOwner = Shared.EmptyBeneficialOwner;
		let controllingPerson: Shared.IControllingPerson | undefined = Shared.EmptyControllingPerson;
		props.investor.controllingPersons.forEach((cp) => {
			let bo: Shared.IBeneficialOwner = cp.beneficialOwners.find((i: Shared.IBeneficialOwner) => i.id === step.id)
			if (bo !== undefined) {
				beneficialOwner = bo;
				controllingPerson = cp;
			}
		});
		await props.updateControllingPerson(controllingPerson);
		await props.updateBeneficialOwner(beneficialOwner);
		controllingPersonBeneficialOwnerDialogStepperRef.current!.setStep(step);
		toggleControllingPersonBeneficialOwnerDialogOpen(true);
		nodeClicked(controllingPerson)
		subNodeClicked(beneficialOwner)
		setFocusColumn('focusColumn3');
		setLastOpenDialog("BeneficialOwner");
	}

	async function openEntityBeneficialOwner(step) {
		closeLastNotificationStep();
		let beneficialOwner: Shared.IBeneficialOwner = props.investor.beneficialOwners.find((bo) => bo.id === step.id);
		await props.updateBeneficialOwner(beneficialOwner);
		entityBeneficialOwnerDialogStepperRef.current!.setStep(step);
		toggleEntityBoDialogOpen(true);
		nodeClicked(beneficialOwner)
		setFocusColumn('focusColumn2');
		setLastOpenDialog("ControllingPersonBeneficialOwner");
	}

	function setEntityBeneficialOwnerDialogStep(step) {
		entityBeneficialOwnerDialogStepperRef.current!.setStep(step);
	}

	const subNodeClicked = (cp) => {
		const activeCP = { ...cp }
		setActiveChild(activeCP)
	}

	const isActive = (id) => {
		return activeControllingPerson.id === id && activeChild.id === undefined;
	}

	const isChildActive = (id) => {
		return activeChild.id === id;
	}

	const isRootNodeActive = (name) => {
		return activeControllingPerson.name === name && activeChild.id === undefined;
	}

	const addControllingPerson = async () => {
		clearDirector();
		await props.updateControllingPerson(Shared.EmptyControllingPerson)
		controllingPersonDialogStepperRef.current!.setStep(0)
		toggleDialogOpen(true)
		setFocusColumn('focusColumn2');
	}

	const editControllingPerson = async (controllingPerson) => {
		await props.updateControllingPerson(controllingPerson)
		controllingPersonDialogStepperRef.current!.setStep(0)
		toggleDialogOpen(true);
		setFocusColumn('focusColumn2');
	}

	const addControllingPersonBeneficialOwner = async (controllingPerson) => {
		deSelectChild();
		await props.updateControllingPerson(controllingPerson)
		await props.updateBeneficialOwner(Shared.EmptyBeneficialOwner)
		controllingPersonBeneficialOwnerDialogStepperRef.current!.setStep(0)
		toggleControllingPersonBeneficialOwnerDialogOpen(true);
		setFocusColumn('focusColumn3');
	}

	const addControllingPersonBOStatus = async (controllingPerson) => {
		await props.updateControllingPerson(controllingPerson)
		await props.updateBeneficialOwner(Shared.EmptyBeneficialOwner)
		toggleControllingPersonBeneficialOwnerStatusDialogOpen(true)
		setFocusColumn('focusColumn3');
	}

	const addEntityBOStatus = async () => {
		await props.updateBeneficialOwner(Shared.EmptyBeneficialOwner)
		toggleEntityBOStatusDialogOpen(true);
		setFocusColumn('focusColumn2');
	}

	const addEntityBo = async () => {
		clearDirector();
		await props.updateBeneficialOwner(Shared.EmptyBeneficialOwner)
		setEntityBeneficialOwnerDialogStep(0)
		toggleEntityBoDialogOpen(true);
		setFocusColumn('focusColumn2');
	}

	const addSecondLevelCP = async (controllingPerson) => {
		deSelectChild();
		let secondLevelCP: Shared.ISecondLevelControllingPerson = Shared.EmptySecondLevelControllingPerson;
		secondLevelCP.controllingPersonId = controllingPerson.id;
		await props.updateSecondLevelControllingPerson(secondLevelCP);
		toggleSecondLevelCPDialogOpen(true);
		setFocusColumn('focusColumn3');
	}
	const editSecondLevelControllingPerson = async (secondLevelCP) => {
		await props.updateSecondLevelControllingPerson(secondLevelCP);
		toggleSecondLevelCPDialogOpen(true)
		setFocusColumn('focusColumn3');
	}

	const editEntityInvestor = () => {
		toggleEntityDialogOpen(true)
		setFocusColumn('focusColumn1');
	}

	const editBeneficialOwner = async (bo) => {
		await props.updateBeneficialOwner(bo);
		toggleEntityBoDialogOpen(true);
		setFocusColumn('focusColumn2');
	}

	const editControllingPersonBO = async (cp, bo) => {
		await props.updateControllingPerson(cp);
		await props.updateBeneficialOwner(bo);
		toggleControllingPersonBeneficialOwnerDialogOpen(true);
		setFocusColumn('focusColumn3');
	}

	const handleSecondLevelDialogClose = async () => {
		secondLevelControllingPersonDialogStepperRef.current!.setStep(0);
		toggleSecondLevelCPDialogOpen(false);
		await populateInvestorData();
		setFocusColumn('');
	}

	const handleDialogClose = async () => {
		await populateInvestorData();
		toggleDialogOpen(!dialogOpen);
		setActiveNotification(undefined);
		setFocusColumn('');
		if (openTour2OnDialogClose) {
			setTimeout(() => {
				setLevel2TourOpen(true);
				setOpenTour2OnDialogClose(false);
			}, 1000);
		}
	}

	const handleBoDialogClose = async () => {
		await populateInvestorData();
		toggleControllingPersonBeneficialOwnerDialogOpen(!controllingPersonBeneficialOwnerDialogOpen);
		setFocusColumn('');
	}

	const handleEntityBoDialogClose = async () => {
		await populateInvestorData();
		toggleEntityBoDialogOpen(!entityBoDialogOpen);
		setFocusColumn('');
	}

	const handleBoStatusDialogClose = async (dialogSwitch?: boolean) => {
		toggleControllingPersonBeneficialOwnerStatusDialogOpen(false);
		await populateInvestorData();
		if (!dialogSwitch) {
			setFocusColumn('');
		}
	}

	const handleEntityBOStatusDialogClose = async (dialogSwitch?: boolean) => {
		toggleEntityBOStatusDialogOpen(false);
		await populateInvestorData();
		if (!dialogSwitch) {
			setFocusColumn('');
		}
	}

	const handleEntityDialogClose = async () => {
		setFocusColumn('');
		if (props.investor.mainWorkflowCompleted) {
			toggleEntityDialogOpen(false);
			await populateInvestorData();
			setFocusColumn('');
			if (window.localStorage.getItem('tour1') !== 'true') {
				setTimeout(() => {
					setLevel1TourOpen(true)
				}, 2000);
			}
		}
	}

	const clickDeleteControllingPerson = async (id) => {
		setShowConnectors(false);
		await ControllingPersonService.deleteControllingPerson(activeComponent.id)
			.then(result => {
			})
		clearDirector();
		confirmDialogToggle(!confirmDialogOpen);
		await populateInvestorData();
		await props.populateValidations();
		setTimeout(() => {
			setShowConnectors(true);
		}, animationInTime);
	}

	const populateInvestorData = async () => {
		await props.updateEntityInvestor();
	}

	const confirmDeleteControllingPerson = (controllingPerson: Shared.IControllingPerson) => {
		setActiveComponent({ id: controllingPerson.id, parentId: '' });
		confirmDialogToggle(true);
	}

	const closeConfirmDialog = () => {
		confirmDialogToggle(false);
	}
	const closeConfirmSecondLevelCPDialog = () => {
		confirmSecondLevelCPDialogToggle(false);
	}

	const confirmDeleteSecondLevelControllingPerson = (secondLevelControllingPerson: Shared.ISecondLevelControllingPerson) => {
		setActiveComponent({
			id: secondLevelControllingPerson.id,
			parentId: secondLevelControllingPerson.controllingPersonId
		});
		confirmSecondLevelCPDialogToggle(true);
	}

	const clickDeleteSecondLevelControllingPerson = async () => {
		setShowL2Connectors(false);
		await SecondLevelControllingPersonService.delete(activeComponent.id, activeComponent.parentId)
			.then(result => {
			})
		confirmSecondLevelCPDialogToggle(false);
		await populateInvestorData();
		await props.populateValidations();
		setTimeout(() => {
			setShowL2Connectors(true);
		}, animationInTime);
	}

	const confirmDeleteControllingPersonBeneficialOwner = async (cp, bo) => {
		setActiveComponent({
			id: bo.id,
			parentId: cp.id
		});
		confirmCPBeneficialOwnerDialogToggle(true);
	}

	const clickDeleteControllingPersonBeneficialOwner = async () => {
		setShowL2Connectors(false);
		await ControllingPersonBeneficialOwnerService.delete(activeComponent.id, activeComponent.parentId)
			.then(result => {
			})
		confirmCPBeneficialOwnerDialogToggle(false);
		await populateInvestorData();
		await props.populateValidations();
		setTimeout(() => {
			setShowL2Connectors(true);
		}, animationInTime);
	}

	const closeCPBeneficialOwnerDialog = () => {
		confirmCPBeneficialOwnerDialogToggle(false);
	}

	const confirmDeleteBeneficialOwner = async (bo: Shared.IBeneficialOwner) => {
		setActiveComponent({ id: bo.id, parentId: '' });
		confirmBeneficialOwnerDialogToggle(true);
	}

	const clickDeleteBeneficialOwner = async () => {
		setShowConnectors(false);
		await InvestorService.deleteBeneficialOwner(activeComponent.id)
			.then(result => {
			})
		confirmBeneficialOwnerDialogToggle(false);
		await populateInvestorData();
		await props.populateValidations();
		setTimeout(() => {
			setShowConnectors(true);
		}, animationInTime);
	}

	const closeBeneficialOwnerDialog = () => {
		confirmBeneficialOwnerDialogToggle(false);
	}

	const clearDirector = () => {
		setActiveControllingPerson({
			id: undefined,
			declareHavingUbo: null,
			residentialAddress: null,
			name: "",
			type: "",
			regulatedEntity: false,
			secondLevelControllingPersons: [
				{ name: '', id: undefined, status: '' }
			],
			beneficialOwners: [
				{ name: '', id: undefined, status: '' }
			],
		});
		deSelectChild();
	}

	const deselect = (e) => {
		// ignore if click happened inside a chart node or button bar
		if (e.nativeEvent.target.closest('.chartNode') || e.nativeEvent.target.closest('.' + styles.buttonBar) || e.nativeEvent.target.tagName === 'BUTTON') return;
		clearDirector();
	}

	function deSelectChild() {
		setActiveChild({
			id: undefined,
			name: "",
			type: "",
		});
	}

	// If CP list has added an entry while the dialog is open, select the new entry
	const prevControllingPersons = usePrevious(props.investor.controllingPersons) || [];
	useEffect(() => {
		const updatedDirector = props.investor.controllingPersons.filter(cp => cp.id === activeControllingPerson.id);
		if (updatedDirector.length > 0) {
			setActiveControllingPerson(updatedDirector[0]);
		}
		if (dialogOpen && props.controllingPerson) {
			setActiveControllingPerson(props.controllingPerson);
			if (props.controllingPerson.type !== null && props.controllingPerson.regulatedEntity !== null) {
				if (props.controllingPerson.type === 'Individual' || props.controllingPerson.regulatedEntity === true || window.localStorage.getItem('tour2') === 'true') {
					setOpenTour2OnDialogClose(false);
				} else {
					setOpenTour2OnDialogClose(true);
				}
			}
		}
	}, [props.investor.controllingPersons, activeControllingPerson.id, dialogOpen, prevControllingPersons, props.controllingPerson])

	// Select the new BO
	let { beneficialOwner } = props;
	useEffect(() => {
		if (!beneficialOwner.id || !entityBoDialogOpen) return;
		if (entityBoDialogOpen) {
			setActiveControllingPerson(beneficialOwner);
		}
	}, [beneficialOwner, entityBoDialogOpen]);

	// select the new secondLevelControllingPerson
	let { secondLevelControllingPerson } = props;
	useEffect(() => {
		if (!secondLevelControllingPerson.id) return;
		if (secondLevelCPDialogOpen) {
			setActiveChild(secondLevelControllingPerson);
		}
	}, [secondLevelControllingPerson, secondLevelCPDialogOpen])

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	useEffect(() => {
		if (focusColumn !== '') return;
		if (animatedInTimeout.current) {
			clearTimeout(animatedInTimeout.current);
		}
		setCol2AnimatedIn(false);
		redrawLines(styles, setConnectorValues);
		animatedInTimeout.current = setTimeout(() => {
			if (col1AnimatedIn !== true) {
				setCol1AnimatedIn(true);
			}
			setCol2AnimatedIn(true);
		}, 1000);
	}, [props.investor.controllingPersons.length, props.investor.beneficialOwners.length, activeControllingPerson, props.investor.declareHavingUbo, focusColumn, col1AnimatedIn])

	useEffect(() => {
		if (focusColumn !== '') {
			setShowConnectors(false);
		} else {
			setTimeout(() => {
				redrawLines(styles, setConnectorValues);
				setShowConnectors(true);
			}, 1000);
		}
	}, [focusColumn])

	const handleEntitySubmit = async (ignoreInvalid) => {
		await ValidationService.validateEntitySubmit()
			.then(async result => {
				GlobalService.sendNotification(result)
				if ((result.totalIssues > 0) && !ignoreInvalid) {
					setConfirmSubmitOpen(true);
				} else {
					await props.submitInvestor();
					history.push('/finished');
				}
			})
	}

	function showSecondLevelControllingPersonColumn() {
		return activeControllingPerson.type &&
			!activeControllingPerson.regulatedEntity &&
			activeControllingPerson.regulatedEntity !== null &&
			activeControllingPerson.type !== "Individual" &&
			activeControllingPerson.type !== "" &&
			!activeControllingPerson.residentialAddress;
	}

	function onAnimationFinished() {
		setTimeout(() => {
			redrawLines(styles, setConnectorValues);
		}, 0);
	}

	return (
		<>
			<div className={`${styles.chart} ${styles[focusColumn]} ${!showConnectors ? styles.hideConnectors : null} ${!showL2Connectors ? styles.hideL2Connectors : null} ${col1AnimatedIn ? styles.animatedIn : ''} ${col2AnimatedIn ? styles.animatedIn2 : ''}`} id="chartEl" key="chartEl" onClick={(e) => deselect(e)}>
				<Connector colour="#B55A85" source="sourceEl" target="directorsEl" connectorValues={connectorValues} key={`svgcontsourceEldirectorsEl`}></Connector>
				<Connector colour="#846CA9" source="sourceEl" target="uboEl" connectorValues={connectorValues} key={`svgcontsourceEluboEl`}></Connector>

				{activeControllingPerson.id ?
					<>
						<Connector colour="#6475AD" source="column2source" target="column3cp" connectorValues={connectorValues} key={`svgcontcolumn2sourcecolumn3cp`}></Connector>
						<Connector colour="#846CA9" source="column2source" target="column3ubo" connectorValues={connectorValues} key={`svgcontcolumn2sourcecolumn3ubo`}></Connector>
					</>
					: null
				}

				<div className={`${styles.column} ${styles.first}`}>
					<div>
						<div className={`${styles.titleSection} ${styles.investorTitle}`}>
							<h3>Investor</h3>
						</div>
						<div className={styles.section} id="sourceEl">
							<div className={`${styles.investorNode}`}>
								<Node
									details={{ name: props.investor.name, type:"Investor" }}
									editClick={editEntityInvestor}
									click={nodeClicked}
									isActive={isRootNodeActive(props.investor.name)}
									section="investor"
									status={props.investor.status}
									activeNotification={activeNotification}
									dialogOpen={dialogOpen}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={`${styles.column} ${styles.second} ${dialogOpen ? styles.focusDirectors : ''} ${entityBoDialogOpen || entityBOStatusDialogOpen ? styles.focusUBOs : ''}`}>
					<ControllingPersonsSection
						dialogOpen={dialogOpen}
						setDirectorsGuideDialogOpen={setDirectorsGuideDialogOpen}
						addControllingPerson={addControllingPerson}
						animationInTime={animationInTime}
						animationOutTime={animationOutTime}
						onAnimationFinished={onAnimationFinished}
						editControllingPerson={editControllingPerson}
						confirmDeleteControllingPerson={confirmDeleteControllingPerson}
						nodeClicked={nodeClicked}
						isActive={isActive}
						activeControllingPerson={activeControllingPerson}
						activeNotification={activeNotification}
						setActiveNotification={setActiveNotification}
						investor={props.investor}
					/>
					{props.investor.beneficialOwners &&
						<BeneficialOwnersSection
							dialogOpen={dialogOpen}
							setUboGuideDialogOpen={setUboGuideDialogOpen}
							entityBoDialogOpen={entityBoDialogOpen}
							addEntityBo={addEntityBo}
							addEntityBOStatus={addEntityBOStatus}
							animationInTime={animationInTime}
							animationOutTime={animationOutTime}
							onAnimationFinished={onAnimationFinished}
							editBeneficialOwner={editBeneficialOwner}
							confirmDeleteBeneficialOwner={confirmDeleteBeneficialOwner}
							nodeClicked={nodeClicked}
							isActive={isActive}
							activeControllingPerson={activeControllingPerson}
							activeNotification={activeNotification}
							setActiveNotification={setActiveNotification}
							investor={props.investor}
						/>
					}
				</div>

				{
					showSecondLevelControllingPersonColumn() ?
						<div className={`${styles.column} ${styles.third} ${controllingPersonBeneficialOwnerDialogOpen || controllingPersonBeneficialOwnerStatusDialogOpen ? styles.focusSLBO : ''} ${secondLevelCPDialogOpen ? styles.focusSLCP : ''}`}>
							{activeControllingPerson.secondLevelControllingPersons &&
								<SecondLevelControllingPersonsSection
									dialogOpen={dialogOpen}
									setSecondLevelDirectorsGuideDialogOpen={setSecondLevelDirectorsGuideDialogOpen}
									secondLevelCPDialogOpen={secondLevelCPDialogOpen}
									addSecondLevelCP={addSecondLevelCP}
									animationInTime={animationInTime}
									animationOutTime={animationOutTime}
									onAnimationFinished={onAnimationFinished}
									editSecondLevelControllingPerson={editSecondLevelControllingPerson}
									confirmDeleteSecondLevelControllingPerson={confirmDeleteSecondLevelControllingPerson}
									subNodeClicked={subNodeClicked}
									isChildActive={isChildActive}
									activeControllingPerson={activeControllingPerson}
									activeChild={activeChild}
									showConnectors={showConnectors}
								/>
							}
							{
								(!activeControllingPerson.declareHavingUbo || activeControllingPerson.beneficialOwners) &&
									<SecondLevelBeneficialOwnerSection
										dialogOpen={dialogOpen}
										setUboGuideDialogOpen={setUboGuideDialogOpen}
										controllingPersonBeneficialOwnerDialogOpen={controllingPersonBeneficialOwnerDialogOpen}
										addControllingPersonBeneficialOwner={addControllingPersonBeneficialOwner}
										addControllingPersonBOStatus={addControllingPersonBOStatus}
										animationInTime={animationInTime}
										animationOutTime={animationOutTime}
										onAnimationFinished={onAnimationFinished}
										editControllingPersonBO={editControllingPersonBO}
										confirmDeleteControllingPersonBeneficialOwner={confirmDeleteControllingPersonBeneficialOwner}
										subNodeClicked={subNodeClicked}
										isChildActive={isChildActive}
										activeControllingPerson={activeControllingPerson}
										activeChild={activeChild}
										showConnectors={showConnectors}
									/>
							}
						</div> : null
				}

			</div>
			<div className={styles.submitBar}>
				<span>Are you done?</span>
				<Button className={styles.nextButton} color="primary" variant="contained" onClick={() => handleEntitySubmit(undefined)} disableElevation>Submit</Button>
			</div>
			<ControllingPersonDialogStepper
				ref={controllingPersonDialogStepperRef}
				isOpen={dialogOpen}
				handleClose={handleDialogClose}
				entityInvestor={props.investor}
				updateEntityInvestor={props.updateEntityInvestor}
				updateControllingPerson={props.updateControllingPerson}
				controllingPerson={props.controllingPerson}
			/>
			<SecondLevelControllingPersonDialogStepper
				ref={secondLevelControllingPersonDialogStepperRef}
				isOpen={secondLevelCPDialogOpen}
				handleClose={handleSecondLevelDialogClose}
				entityInvestor={props.investor}
				updateSecondLevelControllingPerson={props.updateSecondLevelControllingPerson}
				controllingPerson={activeControllingPerson}
				secondLevelControllingPerson={props.secondLevelControllingPerson}
				updateEntityInvestor={props.updateEntityInvestor}
			/>
			<ControllingPersonsBeneficialOwnerDialogStepper
				ref={controllingPersonBeneficialOwnerDialogStepperRef}
				isOpen={controllingPersonBeneficialOwnerDialogOpen}
				handleClose={handleBoDialogClose}
				entityInvestor={props.investor}
				updateEntityInvestor={props.updateEntityInvestor}
				updateControllingPerson={props.updateControllingPerson}
				controllingPerson={props.controllingPerson}
				updateBeneficialOwner={props.updateBeneficialOwner}
				beneficialOwner={props.beneficialOwner}
				countries={props.countries}
			/>
			<ControllingPersonsBOStatusDialogStepper
				isOpen={controllingPersonBeneficialOwnerStatusDialogOpen}
				handleClose={handleBoStatusDialogClose}
				controllingPerson={props.controllingPerson}
				entityInvestor={props.investor}
				updateInvestor={props.updateInvestor}
				updateControllingPerson={props.updateControllingPerson}
				openBeneficialOwnerStepper={addControllingPersonBeneficialOwner}
			/>
			<EntityBOStatusDialogStepper
				isOpen={entityBOStatusDialogOpen}
				handleClose={handleEntityBOStatusDialogClose}
				controllingPerson={props.controllingPerson}
				entityInvestor={props.investor}
				countries={props.countries}
				updateControllingPerson={props.updateControllingPerson}
				openBeneficialOwnerStepper={addEntityBo}
			/>
			<EntityBoDialogStepper
				ref={entityBeneficialOwnerDialogStepperRef}
				isOpen={entityBoDialogOpen}
				handleClose={handleEntityBoDialogClose}
				entityInvestor={props.investor}
				updateEntityInvestor={props.updateEntityInvestor}
				beneficialOwner={props.beneficialOwner}
				updateBeneficialOwner={props.updateBeneficialOwner}
				countries={props.countries}
			/>
			<EntityDialogStepper
				ref={entityDialogStepperRef}
				isOpen={entityDialogOpen}
				handleClose={handleEntityDialogClose}
				countries={props.countries}
				highRiskJurisdictions={props.highRiskJurisdictions}
				investor={props.investor}
				updateEntityInvestor={props.updateEntityInvestor}
				controllingPerson={props.controllingPerson}
				updateControllingPerson={props.updateControllingPerson}
				notifications={props.notifications}
				toggleNotificationsPanel={props.toggleNotificationsOpen}
				finishMainWorkflow={finishMainWorkflow}
			/>
			<ConfirmDialog
				isOpen={confirmDialogOpen}
				handleClose={closeConfirmDialog}
				handleConfirm={clickDeleteControllingPerson}
				title="Are you sure?"
				description="Do you want to delete this controlling person?"
				cancelText="Cancel"
				confirmText="Delete"
			/>
			<ConfirmDialog
				isOpen={confirmSecondLevelCPDialogOpen}
				handleClose={closeConfirmSecondLevelCPDialog}
				handleConfirm={clickDeleteSecondLevelControllingPerson}
				title="Are you sure?"
				description="Do you want to delete this second level controlling person?"
				cancelText="Cancel"
				confirmText="Delete"
			/>
			<ConfirmDialog
				isOpen={confirmCPBeneficialOwnerDialogOpen}
				handleClose={closeCPBeneficialOwnerDialog}
				handleConfirm={clickDeleteControllingPersonBeneficialOwner}
				title="Are you sure?"
				description="Do you want to delete this Beneficial Owner?"
				cancelText="Cancel"
				confirmText="Delete"
			/>
			<ConfirmDialog
				isOpen={confirmBeneficialOwnerDialogOpen}
				handleClose={closeBeneficialOwnerDialog}
				handleConfirm={clickDeleteBeneficialOwner}
				title="Are you sure?"
				description="Do you want to delete this Beneficial Owner?"
				cancelText="Cancel"
				confirmText="Delete"
			/>
			<MessageGuide
				isOpen={directorsGuideDialogOpen}
				handleClose={() => { setDirectorsGuideDialogOpen(false) }}
				title={ControllingPersonService.getControllingPersonsListTitle(props.investor.investorType)}
				cancelText="Close">
				<p>Please provide at least two records which correspond to either the person(s) or entity(s) who exercise control over the investor entity. Do this by clicking an <strong>"Add a new record"</strong> button or the <strong>"+"</strong> button in the top right of the section</p>
			</MessageGuide>
			<MessageGuide
				isOpen={uboGuideDialogOpen}
				handleClose={() => { setUboGuideDialogOpen(false) }}
				title="UBOs"
				cancelText="Close">
				<p>Please establish whether any beneficial owners hold interests of 10% or more in the investor entity. To do this click the <strong>"Beneficial Owner Declaration"</strong> button and answer the question accordingly.</p>
				<p>If yes, please then provide all investors who hold 10% or more by clicking an <strong>"Add a new record"</strong> button or the <strong>"+"</strong> button in the top right of the section .</p>
				<p><i>Note: interests can be held either directly or indirectly.</i></p>
			</MessageGuide>
			<MessageGuide
				isOpen={secondLevelDirectorsGuideDialogOpen}
				handleClose={() => { setSecondLevelDirectorsGuideDialogOpen(false) }}
				title={ControllingPersonService.getControllingPersonsListTitle(activeControllingPerson.type)}
				cancelText="Close">
				<p>Now that you have established an entity as a controlling party to the Investor, the next step is to establish who controls this entity. In this section, please establish two (or more) natural persons who exercise control over the Controlling Entity.</p>
				<p>Where corporate entities exist between the controlling entity and natural persons who exercise control over the controlling entity, please include a structure chart which demonstrates the ownership structure.</p>
			</MessageGuide>
			<Tour
				steps={level1TourSteps(closeTour, styles)}
				isOpen={isLevel1TourOpen}
				onRequestClose={closeTour}
				rounded={10}
				className={styles.tourGuide}
				closeWithMask={false}
				showNavigation={false}
				showButtons={false}
				showCloseButton={false}
				showNumber={false}
				maskClassName={styles.tourMask}
				maskSpace={15}
				highlightedMaskClassName={styles.highlightedElement}
			/>
			<Tour
				steps={level2TourSteps(closeTour)}
				isOpen={isLevel2TourOpen}
				onRequestClose={closeTour}
				rounded={10}
				className={styles.tourGuide}
				closeWithMask={false}
				showNavigation={false}
				showButtons={false}
				showCloseButton={false}
				showNumber={false}
				maskClassName={styles.tourMask}
				maskSpace={15}
				highlightedMaskClassName={styles.highlightedElement}
			/>
			<SubmitChart
				isOpen={confirmSubmitOpen}
				handleClose={() => {
					setConfirmSubmitOpen(false);
					props.toggleNotificationsPanel(false);
				}}
				handleConfirm={() => { handleEntitySubmit(true) }}
				title="We're almost ready to submit your documents for review"
				description=""
				cancelText="Fix Issues"
				confirmText="Submit Anyway"
			>
				<p>There are a few issues we have found that you may wish to attend to before submitting</p>
			</SubmitChart>
		</>
	);
}
const forwardedRef = forwardRef(EntityChart)

export default forwardedRef;