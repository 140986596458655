import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export default function SubmitIndividual(props) {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Ready to submit</DialogTitle>
      <DialogContent>
        Do you want to submit
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleConfirm} variant="contained" color="default" disableElevation>Yes</Button>
        <Button onClick={props.handleClose} variant="contained" color="primary" disableElevation>No</Button>
      </DialogActions>
    </Dialog>
  );
}