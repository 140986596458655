import React, { Component } from 'react';
import { Container } from 'reactstrap';
import styles from './layout.module.scss';
import logo from '../assets/Logo-Blue.svg';
import PubSub from 'pubsub-js';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import HelpDialog from './shared/dialogs/helpDialog';
import SnackbarContentWrapper from './shared/misc/snackBar';

interface IProps {
}

interface IState {
	snackPosition?: SnackbarOrigin,
	snackBarOpen: boolean,
	snackMessage: string,
	snackType: string,
	dialogOpen:boolean
}

export class Layout extends Component<IProps, IState> {
  static displayName = Layout.name;

  constructor(props: IProps) {
	super(props);
	this.state = {
		snackBarOpen: false,
		snackMessage: '',
		snackType: 'success',
		snackPosition: {
			vertical: 'bottom',
			horizontal: 'center'
		},
		dialogOpen: false
	};
	this.showSnack = this.showSnack.bind(this);
	  this.handleSnackBarClose = this.handleSnackBarClose.bind(this);
  }

	componentDidMount() {
	  PubSub.subscribe('SNACKBAR', this.showSnack);
	}

  showSnack(type, content) {
	this.setState({
		snackBarOpen: true,
		snackMessage: content.message,
		snackType: content.variant,
		snackPosition: content.position || { vertical: 'bottom', horizontal: 'center' }
	})
  }

  handleSnackBarClose() {
	this.setState({
		snackBarOpen: false
	})
	}

	handleDialogOpen() {
		this.setState({
			dialogOpen: true
		})
	}

	handleDialogClose() {
		this.setState({
			dialogOpen: false
		})
	}

	render() {

		return (
		<>
      <div>
		<Snackbar anchorOrigin={this.state.snackPosition}
			open={this.state.snackBarOpen}
			autoHideDuration={6000}
			onClose={this.handleSnackBarClose}>
			<SnackbarContentWrapper onClose={this.handleSnackBarClose} variant={this.state.snackType} message={this.state.snackMessage} /></Snackbar>
			<Container className={styles.container}>
				<img className={styles.logo} src={logo} alt="Maples Global" height="30" />
				<div className={styles.helplink}>
					Having troubles? <button onClick={() => this.handleDialogOpen()} className="iconTextButton">Get Help</button>
				</div>
				{this.props.children}
        </Container>
		</div>
		<HelpDialog
			isOpen={this.state.dialogOpen}
			handleClose={() => this.handleDialogClose()}
			title="Get Help"
			description="Help Text"
			cancelText="Close"
		/>
		</>
    );
  }
}
