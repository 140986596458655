import React from 'react';
import styles from './entityChart.module.scss';

export default function Connector(props) {
	return (
		<svg viewBox="0 0 215 200"
			key={`svg${props.source + props.target}`}
			preserveAspectRatio="none"
			shapeRendering="crispEdges"
			className={`${styles.connectorWrap} ${props.source === 'column2source' ? styles.l2connectorWrap : ''}`}
			data-target={props.target}
			data-source={props.source}
			style={{
				height: (props.connectorValues[props.source + props.target] ? props.connectorValues[props.source + props.target].height : '0') + 'px',
				transform: props.connectorValues[props.source + props.target] ? props.connectorValues[props.source + props.target].transform : undefined,
				top: props.connectorValues[props.source + props.target] ? props.connectorValues[props.source + props.target].top : undefined,
				left: props.connectorValues[props.source + props.target] ? props.connectorValues[props.source + props.target].left : undefined,
				visibility: props.connectorValues[props.source + props.target] ? 'visible' : 'hidden'
			}}
		>
			<polyline className={styles.connector} points="0,200 200,200 200,0 215,0" stroke={props.colour} vectorEffect="non-scaling-stroke" />
		</svg>
	)
}