import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Shared from '../shared/sharedTypes';
import { TextFieldMaples } from 'msg-react-lib';
import { ValidationService } from '../services/validationService';
import { Document } from '../shared/documentUpload/documentComponent';
import { GlobalService } from '../services/globalService';
import { DocumentService } from '../services/documentService';
import { InvestorService } from '../services/investorService';
import * as Yup from "yup";
import styles from './enhancedDueDiligence.module.scss';

class State {
	investor: Shared.IInvestor = Shared.EmptyInvestor
	sourceOfFund =
		{
			...Shared.EmptyDocument,
			title: 'Source of Funds Evidence',
			type: 'SourceOfFunds'
		}
}

export class EnhancedDueDiligence extends Component<Shared.IProps, State>{
	state = new State();
	bagRef;
	notificationField: string;
	validationSchema;
	constructor(props: Shared.IProps) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.loadDocuments = this.loadDocuments.bind(this);
		this.uploadDocument = this.uploadDocument.bind(this);
		this.saveDocument = this.saveDocument.bind(this);
		this.clearDocument = this.clearDocument.bind(this);
		this.validateInvestorDocuments = this.validateInvestorDocuments.bind(this);
		this.bagRef = React.createRef();
		this.notificationField = this.props.activeNotification?.notificationKey && this.props.activeNotification?.notificationKey.charAt(0).toLowerCase() + this.props.activeNotification?.notificationKey.slice(1)
		this.validationSchema = this.props.activeNotification ? Yup.object().shape({
			[this.notificationField]: Yup.string().required(this.props.activeNotification?.description)
		}) : null;
	}

	componentDidMount() {
		if (this.props.investor !== undefined) {
			this.setState({
				investor: this.props.investor
			})
		}
		if (this.props.investor?.documents !== undefined) {
			this.loadDocuments()
		}
		this.bagRef.current.setFieldTouched(this.notificationField, true);
	}

	async handleSubmit(values: Shared.IInvestor) {
		let investor = this.props.investor!
		investor.sourceOfFunds = values.sourceOfFunds;
		await InvestorService.update(investor);
		await this.validateInvestorDocuments();
		await this.props.updateInvestor(investor);
	}

	async validateInvestorDocuments() {
		await ValidationService.validateInvestor('SourceOfFunds')
			.then(result => {
				this.props.setCaseIsValid(result.totalIssues === 0)
				GlobalService.sendNotification(result)
			})
		if (this.props.investor?.highRiskInvestor === true) {
			await ValidationService.validateInvestor('ProofOfSourceOfFunds')
				.then(result => {
					this.props.setCaseIsValid(result.totalIssues === 0)
					GlobalService.sendNotification(result)
				})
		} else {
			await ValidationService.removeValidation('ProofOfSourceOfFunds')
				.then(result => {
					this.props.setCaseIsValid(result.totalIssues === 0)
					GlobalService.sendNotification(result)
				})
		}
	}

	private async loadDocuments() {
		var document = this.props.investor!.documents.find(x => x.type === 'SourceOfFunds')
		if (document !== undefined) {
			document.title = 'Source of Funds Evidence'
			this.setState({ sourceOfFund: document })
		} else {
			this.setState({
				sourceOfFund: {
					...Shared.EmptyDocument,
					title: 'Source of Funds Evidence',
					type: 'SourceOfFunds'
				}
			})
		}
	}

	async saveDocument(values: Shared.IDocument) {
		await DocumentService.saveDocument(values)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.validateInvestorDocuments();
					await this.populateInvestorData();
					await this.loadDocuments();
				}
			});
	}

	async uploadDocument(values: Shared.IDocument) {
		if (values.content.files[0] === undefined) {
			return
		}
		const formData = new FormData();
		formData.append('name', values.content.files[0].name);
		formData.append('type', values.type);
		formData.append('content', values.content.files[0]);
		await DocumentService.uploadDocument(formData)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.validateInvestorDocuments();
					await this.populateInvestorData();
					await this.loadDocuments();
				}
			});
	}

	async clearDocument(values: Shared.IDocument) {
		await DocumentService.clearDocument(values.id)
			.then(async response => {
				if (response.errors !== undefined) {
					Object.keys(response.errors).map((key) => (
						GlobalService.sendSnack('error', `${response.errors[key]}`)
					));
				}
				else {
					GlobalService.sendSnack(response.success ? 'success' : 'info', response.message);
					await this.validateInvestorDocuments();
					await this.populateInvestorData();
					await this.loadDocuments();
				}
			});
	}

	async populateInvestorData() {
		await InvestorService.getInvestor()
			.then(async result => {
				await this.props.updateInvestor(result);
			})
	};

	public render() {
		return (
			<div className="stepContent">
				<h1>Source of Funds</h1>
				<div className={`stepLeft`}>
					<div className="overflowText">
						<div>
							<h3 className="stepCount">{`Step ${this.props.currentStep}`}</h3>
							<h2>Source of funds description</h2>
						</div>
						<article>
							<p>Source of Funds requires a description of how the funds for the investment were acquired or accumulated.</p>
							{this.props.investor!.type === "individual" ? <p>Where the subscriber’s salary is the source of funds, please include the employer and occupation in the box provided.</p> : null}

						</article>
					</div>
				</div>
				<div className="stepRight stepDetails">
					<div>
						<Formik validationSchema={this.validationSchema} initialValues={this.props.investor!} onSubmit={this.handleSubmit} innerRef={this.bagRef} initialTouched={{}}>
							{
								(formikProps) => {
									this.props.bindSubmitFormHandler!(formikProps.submitForm);
									this.props.setFormikProps!(formikProps);
									return (
										<Form>
											<div className={styles.formGrid}>
												<Field
													autoFocus
													name="sourceOfFunds"
													component={TextFieldMaples}
													label="Please describe your source of funds here"
													className="textarea"
													rows="4"
													multiline
												/>
											</div>
										</Form>
									)
								}
							}
						</Formik>
						{this.props.investor?.highRiskInvestor === true &&
							<div className={styles.documentContainer}>
								<Document
									document={this.state.sourceOfFund}
									uploadDocument={this.uploadDocument}
									saveDocument={this.saveDocument}
									clearDocument={this.clearDocument} />
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}