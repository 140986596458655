import React from 'react';
import ToolTip from "../shared/toolTips/toolTip";

export const level1TourSteps = (closeTour, styles) => {
	return [
		{
			selector: '#directorsEl .' + styles.buttonBar,
			content: ({ goTo }) => (
				<ToolTip toolDetails={{ classes: ["tourright", "shiftUp20"], closeTour: () => closeTour('tour1'), goTo: () => goTo(1), length: 4, stepOn: 1 }}>
					<p> The next step is to establish who exercises control over the Investor entity.</p>
					<p>In this section, please create two or more records which correspond to either the person(s) or entity(s) who exercise control over the investor entity.</p>
				</ToolTip>
			),
			position: 'right',
			stepInteraction: false,
			style: {
				top: '-20px'
			}
		},
		{
			selector: '#directorsEl .chartNode',
			content: ({ goTo }) => (
				<ToolTip toolDetails={{ classes: ["tourright"], closeTour: () => closeTour('tour1'), goTo: () => goTo(2), length: 4, stepOn: 2 }}>
					<p>To create each record, please click the record icon.</p>
					<p><i>Note: to continue adding more records, click on the + button at the top of the section.</i></p>
				</ToolTip>
			),
			position: 'right',
			stepInteraction: false,
			style: {
				top: '-20px'
			}
		},
		{
			selector: '#uboEl .' + styles.buttonBar,
			content: ({ goTo, inDOM }) => (

				<ToolTip toolDetails={{ classes: ["tourright", "shiftUp20"], closeTour: () => closeTour('tour1'), goTo: () => goTo(3), length: 4, stepOn: 3 }}>
					<p>The next step is to establish whether any beneficial owners hold interests of 10% or more in the investor entity.</p>
					<p><i>Note: interests can be held either directly or indirectly</i></p>
				</ToolTip>
			),
			position: 'right',
			stepInteraction: false,
			style: {
				top: '-20px'
			}
		},
		{
			selector: '#uboEl .chartNode',
			content: () => (
				<ToolTip toolDetails={{ classes: ["tourright"], closeTour: () => closeTour('tour1'), goTo: null, length: 4, stepOn: 4 }}>
					<p>If there are no beneficial owners who hold 10% or more interests, please click on this icon and answer the first question accordingly.</p>
				</ToolTip>
			),
			position: 'right',
			stepInteraction: false,
			style: {
				top: '-20px'
			}
		},
	];
}

export const level2TourSteps = (closeTour) => {
	return [
		{
		selector: '#column3cp',
		content: ({ goTo }) => (
			<ToolTip toolDetails={{ classes: ["tourleft", "shiftUp20"], closeTour: () => closeTour('tour2'), goTo: () => goTo(1), length: 3, stepOn: 1 }}>
				<p>Now that you have established an entity as a controlling party to the Investor, the next step is to establish who controls this entity.</p>
				<p>In this section, please establish two (or more) natural persons who exercise control over the Controlling Entity.</p>
				<p>Where corporate entities exist between the controlling entity and natural persons who exercise control over the controlling entity, please include a structure chart which demonstrates the ownership structure.</p>
			</ToolTip>
		),
		position: 'left',
		stepInteraction: false,
		style: {
			top: '-20px'
		}
	},
	{
		selector: '#column3ubo',
		content: ({ goTo }) => (
			<ToolTip toolDetails={{ classes: ["tourleft", "shiftUp20"], closeTour: () => closeTour('tour2'), goTo: () => goTo(2), length: 3, stepOn: 2 }}>
				<p>The next step is to establish whether any beneficial owners hold interests of 10% or more in the controlling entity (interests can be held either directly or indirectly).</p>
			</ToolTip>
		),
		position: 'left',
		stepInteraction: false,
		style: {
			top: '-20px'
		}
	},
	{
		selector: '#column3ubo .chartNode',
		content: () => (
			<ToolTip toolDetails={{ classes: ["tourleft", "shiftUp20"], closeTour: () => closeTour('tour2'), goTo: null, length: 3, stepOn: 3 }}>
				<p>If there are no beneficial owners who hold 10% or more interests, please click on this icon and answer the first question accordingly.</p>
			</ToolTip>
		),
		position: 'left',
		stepInteraction: false,
		style: {
			top: '-20px'
		}
	}
	];
}