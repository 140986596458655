import React from 'react';
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { InvestorService } from '../services/investorService';

export function SubmitCase() {
    const history = useHistory();

    return (
        <Button variant="contained" color="primary" onClick={async () => {
            await InvestorService.submitInvestor()
            history.push('/finished');
        }} disableElevation>Submit package for review</Button>
    );
};