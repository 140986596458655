import * as Shared from '../shared/sharedTypes';
import { UnauthorizedService } from './unauthorizedService';

export class SecondLevelControllingPersonService {
	static async getSecondLevelControllingPerson(id: string, controllingPersonId: string): Promise<Shared.ISecondLevelControllingPerson> {
		return UnauthorizedService.authorizedFetch("/api/SecondLevelControllingPerson?id=" + id + "&controllingPersonId=" + controllingPersonId, {
			method: "get",
			headers: {
				"Content-type": "application/json"
			}
		}).then(response => UnauthorizedService.checkUnauthorized(response).json());
	}

	static async save(secondLevelControllingPerson: Shared.ISecondLevelControllingPerson) {
		if (secondLevelControllingPerson.id !== '') {
			return await this.updateSecondLevelControllingPerson(secondLevelControllingPerson);
		} else {
			return await this.addSecondLevelControllingPerson(secondLevelControllingPerson);
		}
	}

	static async addSecondLevelControllingPerson(secondLevelControllingPerson: Shared.ISecondLevelControllingPerson) {
		const response = await UnauthorizedService.authorizedFetch("api/SecondLevelControllingPerson", {
			method: 'post',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(secondLevelControllingPerson)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}

	static async updateSecondLevelControllingPerson(secondLevelControllingPerson: Shared.ISecondLevelControllingPerson) {
		const response = await UnauthorizedService.authorizedFetch("api/SecondLevelControllingPerson/UpdateName", {
			method: 'put',
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(secondLevelControllingPerson)
		});
		return UnauthorizedService.checkUnauthorized(response).json();
	}
	
	static async delete(id: string, controllingPersonId: string) {
		const response = await UnauthorizedService.authorizedFetch("/api/SecondLevelControllingPerson?id=" + id + "&controllingPersonId=" + controllingPersonId, {
			method: 'delete',
			headers: {
				"Content-type": "application/json"
			}
		});		
    return UnauthorizedService.checkUnauthorized(response).json();
  }
}