import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextFieldMaples } from 'msg-react-lib';
import { AccountService } from './services/accountService';
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

export default function CompleteSignup() {
	const [showError, setShowError] = useState(false);
	const history = useHistory();
	const queryParams = new URLSearchParams(window.location.search);
	const uid = queryParams.get("uid") ? queryParams.get("uid") : "";
	const password = queryParams.get("token") ? queryParams.get("token") : "";
	const formInitialValues = {
		uid: uid,
		password: password,
		newPassword: "",
		confirmPassword:""
	}

	useEffect(() => {
		var userSession = localStorage.getItem("jwtToken");
		if (userSession !== null) {
			localStorage.removeItem('jwtToken');
		}			
	});

	const isAccountActivation = () => {
		return (uid !== "" && password !== "");
	}

	const schema = Yup.object({
		newPassword: Yup.string()
			.required('Password is required')
			.matches(
				/^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[\$\%\&])|(?=.*[A-Z])(?=.*\d)(?=.*[\$\%\&])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\$\%\&])).{12,}$/,
				"Must Contain Minimum 12 Characters. Should have atleast 3 of the 4 types - Uppercase, Lowercase, Number, Special Case Character"
			),
		confirmPassword: Yup.string().required('Confirm Password is required')
			.oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
	});

	const handleSubmit = async (values) => {
		var credentials = {
			UserName: uid,
			Password: password,
			NewPassword: values.newPassword,
			ConfirmPassword: values.confirmPassword
		};
		let success = await AccountService.completeSignUp(credentials);
		if (success) {
			await history.push('/')
		}
		else {
			setShowError(true);
		}
		
	}

	const cancelChangePassword = async (values) => {
		await history.push('/signin')
	}

	return (
		< >
			<h1>Set Password</h1>
			{showError ? <h3>Error occured. Please try again after 5 minutes.</h3> : null}
			<Formik enableReinitialize initialValues={formInitialValues} validationSchema={schema} onSubmit={handleSubmit}>
				{
					({ dirty }) => (
						<Form id="myForm">
							<div className="row">
								<Field name="uid" component={TextFieldMaples} label="USER ID" className="col-2" disabled={isAccountActivation} />
							</div>
							<div className="row">
								<Field name="newPassword" component={TextFieldMaples} type="password" label="NEW PASSWORD" className="col-2" required />
							</div>
							<div className="row">
								<Field name="confirmPassword" component={TextFieldMaples} type="password" label="CONFIRM NEW PASSWORD" className="col-2" />
							</div>
							<button
								color="primary"
								type="submit"
								disabled={!dirty}
							>
								Submit
							</button>
							<button
								type="button"
								onClick={cancelChangePassword}
								disabled={!dirty}
							>
								<span></span>Cancel
							</button>
						</Form>
					)
				}
			</Formik>
		</>);
}