import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DialogContent, DialogActions } from '@material-ui/core';
import { Document } from '../../shared/documentUpload/documentComponent';

export default function SubmitProofOfSurrenderedUsCitizenship(props) {
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState<DialogProps['maxWidth']>('xs');

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={props.isOpen}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Proof Of Surrendered U.S. Citizenship</DialogTitle>
      <DialogContent>
        <Document
          document={props.document}
          uploadDocument={props.uploadDocument}
          saveDocument={props.saveDocument}
          clearDocument={props.clearDocument} />
      </DialogContent>
      <DialogActions><Button onClick={props.handleClose} color="primary">Done</Button></DialogActions>
    </Dialog>
  );
}